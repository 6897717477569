import React, { useEffect } from 'react';
import { BrowserRouter, withRouter, Redirect } from 'react-router-dom';

//internal
import { PublicRoute, PrivateRoute } from './utils/Routes';
import AppBar from './components/AppBar';
import Login from './containers/Login';
import Worklogs from './containers/Worklogs';
import Reports from './containers/Reports';
import Register from './containers/Register';
import TimeOff from './containers/TimeOff';
import Invoice from './containers/Invoice';
import Logs from './containers/Logs';
import Dashboard from './containers/Dashboard';
import { isAuthenticated } from './helpers/AuthHelpers';
import { firstDayOfWeek, lastDayOfWeek } from './helpers/DateHelpers'

const Main = withRouter(({ location }) => {
  return (
    <React.Fragment>
      {location.pathname !== "/" && <AppBar />}
      <PublicRoute exact path="/" component={Login} />
      <PrivateRoute exact path="/Worklogs" component={Worklogs} />
      <PrivateRoute exact path="/Timeoff" component={TimeOff} />
      <PrivateRoute exact path="/Reports" component={Reports} />
      <PrivateRoute exact path="/Register" component={Register} />
      <PrivateRoute exact path="/Invoice" component={Invoice} />
      <PrivateRoute exact path="/Dashboard" component={Dashboard} />
      <PrivateRoute exact path="/Logs" component={Logs} />
    </React.Fragment>
  )
})

export default function App() {
  useEffect(() => {
    sessionStorage.setItem("startDate", JSON.stringify({ startDate: firstDayOfWeek, endDate: lastDayOfWeek }))
    if (window.location.pathname !== "/" && !isAuthenticated()) {
      return <Redirect to="/" />
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  )
}