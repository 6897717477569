import React from 'react';
import medium from '../fonts/Roboto-Medium.ttf';
import bold from '../fonts/Roboto-Bold.ttf';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({ family: 'Roboto', fonts: [{ src: medium, fontWeight: 300 }, { src: bold, fontWeight: 700 }] });

export default function InvoicePDF({ number, date, totalHours, formattedHours, signature }) {

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Text style={styles.title}>
                    Anexă la factura NR. {number} din data de {date}
                </Text>

                <Text style={styles.totalHours}>
                    Prestări servicii programare orientată {totalHours} ore total.
                </Text>

                {formattedHours.map((value, index) => {
                    return (
                        <View key={index}>
                            <Text style={styles.projectName}>
                                {index + 1}. {value.name} - {value.value.reduce((total, obj) => obj.workedHours + total, 0)}h
                            </Text>
                            {value.value.map((hour, index) => (
                                <View key={index} style={styles.tasksView}>
                                    <Text style={styles.taskHours}>
                                        {'\u2022'}  {hour.task} - {hour.workedHours}h
                                    </Text>
                                </View>
                            )
                            )}
                        </View>
                    )
                }
                )}
            </Page>
        </Document>
    )
}

const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35
    },
    title: {
        fontSize: 16,
        textAlign: 'center',
        fontWeight: 700,
        fontFamily: 'Roboto'
    },
    totalHours: {
        marginTop: 20,
        fontSize: 12,
        fontWeight: 700,
        fontFamily: 'Roboto'
    },
    projectName: {
        marginTop: 20,
        fontSize: 12,
        fontWeight: 700,
        fontFamily: 'Roboto'
    },
    tasksView: {
        marginLeft: 20
    },
    taskHours: {
        fontSize: 10,
        fontWeight: 300,
        fontFamily: 'Roboto'
    }
})