import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Button, Typography, FormControl, InputLabel, Checkbox, IconButton, InputAdornment, TextField, OutlinedInput, Radio } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

//internal
import Colors from '../globals/Colors';
import SnackBar from './Snackbar';
import DateRangeModal from './DateRangeModal';
import {firstDay, lastDay} from '../helpers/DateHelpers'
import { getPrimaryProjects, getClients } from '../globals/Routes';

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: 450
    },
    title: {
        fontSize: 22,
        fontWeight: 700
    },
    dateButton: {
        marginRight: -15,
        '&:hover': {
            backgroundColor: "transparent"
        }
    },
    select: {
        width: "100%",
        marginTop: 20,
        '& .MuiInputLabel-root.Mui-focused': {
            color: Colors.firstColor,
        },
    },
    selectBorder: {
        '&:hover': {
            borderColor: Colors.firstColor,
        },
        '&:before': {
            borderColor: Colors.firstColor,
        },
        '&:after': {
            borderColor: Colors.firstColor,
        }
    },
    actionButtons: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        fontSize: 14,
        marginTop: 20,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
    textFieldLabel: {
        "&$focusedLabel": {
            color: Colors.firstColor
        },
    },
    focusedLabel: {},
    radioButton: {
        color: Colors.firstColor,
        '&$checked': {
            color: Colors.firstColor,
        },
    }
})

const icon = <CheckBoxOutlineBlankIcon fontSize="small" style={{ color: Colors.firstColor }} />
const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: Colors.firstColor }} />

function ReportsFilterModal({ setFilters, clearFilters }, ref) {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)

    const statutes = [{ id: 2, title: 'All' }, { id: 1, title: 'Confirmed' }, { id: 0, title: 'Pending' }]
    const [statusValue, setStatusValue] = useState(JSON.parse(sessionStorage.getItem("reportsFilteredStatus")) || statutes[0].id)
    const [consistenceStatus, setConsistenceStatus] = useState(JSON.parse(sessionStorage.getItem("consistenceReportsFilteredStatus")) || statutes[0])

    const [projects, setProjects] = useState([])
    const [shownProjects, setShownProjects] = useState([])
    const [projectValue, setProjectValue] = useState(JSON.parse(sessionStorage.getItem("reportsFilteredProjects")) || [])
    const [consistenceProject, setConsistenceProject] = useState(JSON.parse(sessionStorage.getItem("consistenceReportsFilteredProjects")) || [])

    const [clients, setClients] = useState([])
    const [clientValue, setClientValue] = useState(JSON.parse(sessionStorage.getItem("reportsFilteredClients")) || [])
    const [consistenceClient, setConsistenceClient] = useState(JSON.parse(sessionStorage.getItem("consistenceReportsFilteredClients")) || [])

    const [date, setDate] = useState(JSON.parse(sessionStorage.getItem("reportsFilteredDate")) || null)
    const [startDate, setStartDate] = useState(firstDay)
    const [endDate, setEndDate] = useState(lastDay)

    const dateRangeModalRef = useRef()
    const snackbarRef = useRef()

    const applySessionStorage = () => {
        sessionStorage.setItem('consistenceReportsFilteredStatus', JSON.stringify(consistenceStatus))
        sessionStorage.setItem("reportsFilteredStatus", JSON.stringify(statusValue))
        sessionStorage.setItem('consistenceReportsFilteredProjects', JSON.stringify(consistenceProject))
        sessionStorage.setItem("reportsFilteredProjects", JSON.stringify(projectValue))
        sessionStorage.setItem('consistenceReportsFilteredClients', JSON.stringify(consistenceClient))
        sessionStorage.setItem("reportsFilteredClients", JSON.stringify(clientValue))
        sessionStorage.setItem("reportsFilteredDate", JSON.stringify(date))
    }

    const handleStatusChange = (event, newValue) => {
        setStatusValue(newValue ? newValue.id : null)
        setConsistenceStatus(newValue)
    }

    const handleProjectChange = (event, newValue) => {
        if (newValue != null) {
            setProjectValue(newValue.map(value => value.id))
            setConsistenceProject(newValue)
        }
    }

    const handleClientChange = (event, newValue) => {
        if (newValue != null) {
            setClientValue(newValue.map(value => value.id))
            setConsistenceClient(newValue)
        }
    }

    const handleDateChange = date => {
        setDate(date)
    }

    const setDates = data => {
        setStartDate(transformDate(data.startDate))
        setEndDate(transformDate(data.endDate))
    }

    const resetValues = () => {
        setStatusValue(statutes[0].id)
        sessionStorage.removeItem("reportsFilteredStatus")
        sessionStorage.removeItem('consistenceReportsFilteredStatus')
        setProjectValue([])
        sessionStorage.removeItem("reportsFilteredProjects")
        sessionStorage.removeItem('consistenceReportsFilteredProjects')
        setClientValue([])
        sessionStorage.removeItem("reportsFilteredClients")
        sessionStorage.removeItem('consistenceReportsFilteredClients')
        setDate(null)
        sessionStorage.removeItem("reportsFilteredDate")
        sessionStorage.removeItem("reportsFilters")
        setStartDate(firstDay)
        setEndDate(lastDay)
        setConsistenceStatus(statutes[0])
        setConsistenceProject([])
        setConsistenceClient([])
        getDatabaseData()
    }

    const onCancel = () => {
        setDate(JSON.parse(sessionStorage.getItem('reportsFilteredDate')) || null)
        setStatusValue(JSON.parse(sessionStorage.getItem('reportsFilteredStatus')) || statutes[0].id)
        setConsistenceStatus(JSON.parse(sessionStorage.getItem('consistenceReportsFilteredStatus')) || statutes[0])
        setProjectValue(JSON.parse(sessionStorage.getItem('reportsFilteredProjects')) || [])
        setConsistenceProject(JSON.parse(sessionStorage.getItem('consistenceReportsFilteredProjects')) || [])
        setClientValue(JSON.parse(sessionStorage.getItem('reportsFilteredClients')) || [])
        setConsistenceClient(JSON.parse(sessionStorage.getItem('consistenceReportsFilteredClients')) || [])
        getDatabaseData()
    }

    const openSnackBar = (message, severity = "success") => {
        snackbarRef.current.setOpen(true)
        snackbarRef.current.setMessage(message)
        snackbarRef.current.setSeverity(severity)
    }

    const getDatabaseData = () => {
        axios.get(getPrimaryProjects, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            params: { userId: JSON.parse(localStorage.getItem("user")).role !== 1 ? JSON.parse(localStorage.getItem("user")).id : null }
        }).then(response => {
            if (!response.data.length) {
                openSnackBar(i18n.t("filterModal.errorProjects"), "error")
            } else {
                setProjects(response.data)
                setShownProjects(response.data)
            }
        })

        axios.get(getClients, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackBar(i18n.t("filterModal.errorClients"), "error")
            } else {
                setClients(response.data)
            }
        })
    }

    const transformDate = value => {
        let date = new Date(value),
            month = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), month, day].join("-");
    }

    const renderProjectValues = selected => {
        let shownValues = projects.filter(project => selected.includes(project.id)).map(project => { return `${project.projectName} - ${project.clientName}` })
        return [...shownValues].join(", ")
    }

    const renderClientValues = selected => {
        let shownValues = clients.filter(client => selected.includes(client.id)).map(client => { return client.name })
        return [...shownValues].join(", ")
    }

    useEffect(() => {
        let selectedClients = consistenceClient.map(client => { return client.name })
        let projectsByClient = projects.filter(project => selectedClients.includes(project.clientName))

        projectsByClient?.length && setShownProjects(projectsByClient)
    }, [clientValue, consistenceClient, projects])

    useEffect(() => {
        getDatabaseData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (date) {
            date.map(data =>
                setDates(data)
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])

    useImperativeHandle(ref, () => ({
        setOpen,
        setStatusValue,
        setProjectValue,
        setClientValue,
        setDate,
        setStartDate,
        setEndDate,
        setConsistenceStatus,
        setConsistenceProject,
        setConsistenceClient
    }))

    return (
        <Dialog open={open}
            scroll="body"
            maxWidth="md"
            onClose={() => { setOpen(false); onCancel() }}
            PaperProps={{
                style: {
                    backgroundColor: Colors.thirdColor
                }
            }}>
            <DialogContent className={classes.root}>
                <Typography className={classes.title}>Filter reports</Typography>

                <FormControl
                    className={classes.select}
                    variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{i18n.t("filterModal.dateRange")}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        value={moment(startDate).format('MMM DD, YYYY') + " - " + moment(endDate).format('MMM DD, YYYY')}
                        onClick={() => {
                            dateRangeModalRef.current.setOpen(true)
                            dateRangeModalRef.current.setDate([
                                {
                                    startDate: new Date(startDate),
                                    endDate: new Date(endDate),
                                    key: 'selection'
                                }
                            ])
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    disableRipple={true}
                                    className={classes.dateButton}
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        dateRangeModalRef.current.setOpen(true)
                                        dateRangeModalRef.current.setDate([
                                            {
                                                startDate: new Date(startDate),
                                                endDate: new Date(endDate),
                                                key: 'selection'
                                            }
                                        ])
                                    }}
                                >
                                    <DateRangeIcon
                                        style={{ width: 25, height: 25 }} />
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={82}
                    />
                </FormControl>

                <DateRangeModal
                    ref={dateRangeModalRef}
                    onChangeDate={handleDateChange}
                />

                <Autocomplete
                    multiple
                    className={classes.select}
                    disableCloseOnSelect
                    options={clients}
                    value={consistenceClient}
                    onChange={handleClientChange}
                    getOptionLabel={(option) => (option.name)}
                    getOptionSelected={(option, value) => value && option.id === value.id}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                        </React.Fragment>
                    )}
                    renderTags={option => renderClientValues(clientValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={i18n.t("filterModal.filterByClient")}
                            variant="outlined"
                        />
                    )}
                />

                <Autocomplete
                    multiple
                    className={classes.select}
                    disableCloseOnSelect
                    options={shownProjects}
                    value={consistenceProject}
                    onChange={handleProjectChange}
                    getOptionSelected={(option, value) => value && option.id === value.id}
                    getOptionLabel={(option) => (option.projectName + " - " + option.clientName)}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.projectName + " - " + option.clientName}
                        </React.Fragment>
                    )}
                    renderTags={option => renderProjectValues(projectValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={i18n.t("filterModal.filterByProject")}
                            variant="outlined"
                        />
                    )}
                />

                <Autocomplete
                    className={classes.select}
                    disableClearable
                    options={statutes}
                    value={consistenceStatus}
                    onChange={handleStatusChange}
                    getOptionLabel={(option) => (option.title)}
                    getOptionSelected={(option, value) => value && option.id === value.id}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Radio
                                color={Colors.firstColor}
                                className={classes.radioButton}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.title}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Filter by status'
                            variant="outlined"
                        />
                    )}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    className={classes.actionButtons}
                    onClick={() => { setOpen(false); setFilters({ startDate, endDate, statusValue, projectValue, clientValue }); applySessionStorage() }}>
                    {i18n.t("filterModal.apply")}
                </Button>
                <Button
                    className={classes.actionButtons}
                    onClick={() => { setOpen(false); resetValues(); clearFilters() }}
                    style={{ marginLeft: 20 }}>
                    {i18n.t("filterModal.clear")}
                </Button>
                <Button
                    className={classes.actionButtons}
                    style={{ marginLeft: 20 }}
                    onClick={() => { setOpen(false); onCancel() }}>
                    {i18n.t("filterModal.cancel")}
                </Button>
            </DialogActions>
            <SnackBar ref={snackbarRef} />
        </Dialog>
    )
}

export default forwardRef(ReportsFilterModal)