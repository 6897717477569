import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Typography, Button, TextField, Checkbox, Radio } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import locale from 'date-fns/locale/en-US';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import 'react-multi-date-picker/styles/colors/teal.css';

//internal
import Colors from '../globals/Colors';
import SnackBar from './Snackbar';
import { worklog, getUsers, getPrimaryProjects } from '../globals/Routes';
import { getDatesRange } from '../helpers/DateHelpers'

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        marginTop: 10,
    },
    generalField: {
        marginTop: 15,
        width: 400,
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.firstColor
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: Colors.firstColor
        },
    },
    datePickerFocused: {
        borderColor: Colors.firstColor,
        color: Colors.firstColor
    },
    time: {
        fontSize: 20,
        marginLeft: 5
    },
    actionButtons: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        fontSize: 14,
        marginTop: 20,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
    textFieldLabel: {
        "&$focusedLabel": {
            color: Colors.firstColor
        },
    },
    focusedLabel: {},
    emptyTime: {
        color: Colors.redColor,
        fontSize: 12,
        width: "100%",
        textAlign: "left",
        marginLeft: 10
    },
    select: {
        width: 400,
        borderWidth: "2px solid red",
        marginTop: 20,
        '& .MuiInputLabel-root.Mui-focused': {
            color: Colors.firstColor,
        }
    },
    selectBorder: {
        borderWidth: "2px solid red",
        '&:hover': {
            borderColor: Colors.firstColor,
        },
        '&:before': {
            borderColor: Colors.firstColor,
        },
        '&:after': {
            borderColor: Colors.firstColor,
        }
    },
    datePicker: {
        width: '100%'
    },
    errorText: {
        textAlign: "left",
        width: "100%",
        fontSize: 12,
        marginLeft: 10,
        color: Colors.redColor,
        fontWeight: 500
    },
    radioButton: {
        color: Colors.firstColor,
        '&$checked': {
            color: Colors.firstColor,
        },
    }
})

if (locale && locale.options) {
    locale.options.weekStartsOn = 1
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" style={{ color: Colors.firstColor }} />
const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: Colors.firstColor }} />

function WorkLogModal({ onInserted, onUpdated, insertedDate }, ref) {
    const classes = useStyles()

    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)

    const [currentUser, setCurrentUser] = useState()

    const [id, setId] = useState(null)

    const [date, setDate] = useState("")
    const [dateError, setDateError] = useState("")
    const [duration, setDuration] = useState(":")

    const [userValue, setUserValue] = useState(JSON.parse(sessionStorage.getItem("worklogUser")) || [])
    const [consistenceUser, setConsistenceUser] = useState(JSON.parse(sessionStorage.getItem("worklogConsistenceUser")) || [])
    const [users, setUsers] = useState([])
    const [userError, setUserError] = useState("")

    const [projects, setProjects] = useState([])
    const [projectValue, setProjectValue] = useState(JSON.parse(sessionStorage.getItem("worklogProject")) || null)
    const [consistenceProject, setConsistenceProject] = useState(JSON.parse(sessionStorage.getItem("worklogConsistenceProject")) || null)
    const [projectError, setProjectError] = useState("")

    const [hours, setHours] = useState(JSON.parse(sessionStorage.getItem('worklogHours')) || "")
    const [hoursError, setHoursError] = useState("")

    const [minutes, setMinutes] = useState(JSON.parse(sessionStorage.getItem('worklogMinutes')) || "")
    const [minutesError, setMinutesError] = useState("")

    const [emptyTime, setEmptyTime] = useState(false)

    const [task, setTask] = useState(JSON.parse(sessionStorage.getItem('worklogTask')) || "")
    const [taskError, setTaskError] = useState("")

    const [description, setDescription] = useState(JSON.parse(sessionStorage.getItem('worklogDescription')) || "")

    const [worklogDate, setWorklogDate] = useState()

    const snackbarRef = useRef()
    const datePickerRef = useRef()

    const handleDateChange = date => {
        setDate([date.toDate()])
    }

    const handleEditUserChange = (event, newValue) => {
        setUserError("")
        if (newValue != null) {
            setUserValue([newValue.id])
            setConsistenceUser([newValue])
        }
    }

    const handleUserChange = (event, newValue) => {
        setUserError("")
        if (newValue != null) {
            setUserValue(newValue.map(value => value.id))
            setConsistenceUser(newValue)
            !id && sessionStorage.setItem('worklogConsistenceUser', JSON.stringify(newValue))
            !id && sessionStorage.setItem("worklogUser", JSON.stringify(newValue.map(value => value.id)))
        }
    }

    const handleProjectChange = (event, newValue) => {
        setProjectError("")
        setProjectValue(newValue ? newValue.id : null)
        setConsistenceProject(newValue)
        !id && sessionStorage.setItem('worklogConsistenceProject', JSON.stringify(newValue))
        !id && sessionStorage.setItem("worklogProject", JSON.stringify(newValue ? [newValue.id] : null))
    }

    const handleHoursChange = event => {
        setHoursError("")
        setHours(event.target.value)
        !id && sessionStorage.setItem('worklogHours', JSON.stringify(event.target.value))
    }

    const handleMinutesChange = event => {
        setMinutesError("")
        setMinutes(event.target.value)
        !id && sessionStorage.setItem('worklogMinutes', JSON.stringify(event.target.value))
    }

    const handleTaskChange = event => {
        setTaskError("")
        setTask(event.target.value)
        !id && sessionStorage.setItem('worklogTask', JSON.stringify(event.target.value))
    }

    const handleDescriptionChange = event => {
        setDescription(event.target.value)
        !id && sessionStorage.setItem('worklogDescription', JSON.stringify(event.target.value))
    }

    const validateDate = () => {
        if (!date) {
            setDateError("Date can't be empty")
            return false
        } else {
            setDateError("")
            return true
        }
    }

    const validateUser = () => {
        if (currentUser.role === 2) {
            return true
        } else if (!userValue.length) {
            setUserError("User can't be empty")
            return false
        } else {
            setUserError("")
            return true
        }
    }

    const validateProject = () => {
        if (!projectValue) {
            setProjectError(i18n.t("worklogModal.chooseProject"))
            return false
        } else {
            setProjectError("")
            return true
        }
    }

    const validateHours = () => {
        if (/[0-9]/.test(hours) || hours === "") {
            setHoursError("")
            return true
        } else {
            setHoursError(i18n.t("worklogModal.numbersHours"))
            return false
        }
    }

    const validateMinutes = () => {
        if (["", "0", "00", "15", "30", "45"].includes(minutes)) {
            setMinutesError("")
            return true
        } else {
            setMinutesError(i18n.t("worklogModal.numbersMinutes"))
            return false
        }
    }

    const validateDuration = () => {
        if ((hours === "0" || hours === "00" || hours === "") && minutes === "") {
            setEmptyTime(true)
            return false
        } else {
            setEmptyTime(false)
            return true
        }
    }

    const validateTask = () => {
        if (task !== "") {
            setTaskError("")
            return true
        } else {
            setTaskError(i18n.t("worklogModal.emptyTask"))
            return false
        }
    }

    const validate = () => {
        if (validateDate() &&
            validateUser() &&
            validateProject() &&
            validateHours() &&
            validateMinutes() &&
            validateDuration() &&
            validateTask()) {
            return true
        }
        return false
    }

    const timeStringToFloat = time => {
        var hoursMinutes = time.split(/[.:]/)
        var hours = hoursMinutes[0] ? parseInt(hoursMinutes[0], 10) : 0
        var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0
        return hours + minutes / 60
    }

    const resetWorklogData = () => {
        setOpen(false)
        setTimeout(() => {
            setId(null)
            setUserValue(JSON.parse(sessionStorage.getItem("worklogUser")) || [])
            setConsistenceUser(JSON.parse(sessionStorage.getItem("worklogConsistenceUser")) || [])
            setProjectValue(JSON.parse(sessionStorage.getItem("worklogProject")))
            setConsistenceProject(JSON.parse(sessionStorage.getItem("worklogConsistenceProject")))
            setDate("")
            setHours("")
            setMinutes("")
            setTask("")
            setDescription("")
            setDuration(":")
            setHoursError("")
            setMinutesError("")
            setTaskError("")
            setDateError("")
            setUserError("")
            setEmptyTime(false)
            sessionStorage.removeItem('worklogHours')
            sessionStorage.removeItem('worklogMinutes')
            sessionStorage.removeItem('worklogTask')
            sessionStorage.removeItem('worklogDescription')
        }, 100)
    }

    const onCancel = () => {
        setOpen(false)
        setTimeout(() => {
            setId(null)
            setUserValue([])
            setConsistenceUser([])
            setProjectValue(null)
            setConsistenceProject(null)
            setDate("")
            setHours("")
            setMinutes("")
            setTask("")
            setDescription("")
            setDuration(":")
            setHoursError("")
            setMinutesError("")
            setTaskError("")
            setDateError("")
            setUserError("")
            setProjectError("")
            setEmptyTime(false)
            sessionStorage.removeItem('worklogUser')
            sessionStorage.removeItem('worklogConsistenceUser')
            sessionStorage.removeItem('worklogProject')
            sessionStorage.removeItem('worklogConsistenceProject')
            sessionStorage.removeItem('worklogHours')
            sessionStorage.removeItem('worklogMinutes')
            sessionStorage.removeItem('worklogTask')
            sessionStorage.removeItem('worklogDescription')
        }, 500)
    }

    const openSnackBar = (message, severity = "success") => {
        snackbarRef.current.setOpen(true)
        snackbarRef.current.setMessage(message)
        snackbarRef.current.setSeverity(severity)
    }

    const addEntry = () => {
        const worklogDateArray = getDatesRange(moment(worklogDate.startDate).format('YYYY-MM-DD'), moment(worklogDate.endDate).format('YYYY-MM-DD'))
        const modalDateArray = getDatesRange(moment(date[0]).format('YYYY-MM-DD'), moment(date[date.length - 1]).format('YYYY-MM-DD'))

        if (validate()) {
            if (minutes === "") {
                setMinutes("")
            }

            const dbDuration = timeStringToFloat(hours + ":" + minutes)
            let sqlArray = []

            const formattedTask = task.replace(/\s{2,}/g, ' ').trim()
            const formattedDescription = description.replace(/\s{2,}/g, ' ').trim()

            if (currentUser.role === 2) {
                date.map(date => {
                    return sqlArray.push([projectValue, currentUser.id, new Date(date).toISOString().slice(0, 10), dbDuration, formattedTask, formattedDescription, 0])
                })
            } else {
                userValue.map(userId =>
                    date.map(date => {
                        return sqlArray.push([projectValue, userId, new Date(date).toISOString().slice(0, 10), dbDuration, formattedTask, formattedDescription, 0])
                    }))
            }

            axios.post(worklog, {
                sqlArray: sqlArray
            },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                }
            ).then(response => {
                if (response.status === 201 || response.status === 200) {
                    onInserted()
                    insertedDate(worklogDateArray.some(item => modalDateArray.indexOf(item) >= 0))
                    resetWorklogData()
                }
            }).catch(error =>
                openSnackBar(i18n.t("worklogModal.errorWorklogAdd"), "error")
            )
        }
    }

    const updateWorklog = () => {
        const dbDuration = timeStringToFloat(hours + ":" + minutes)
        if (validate()) {
            axios.patch(worklog + "?id=" + id, {
                project_id: projectValue,
                user_id: userValue,
                date: moment(date[0]).format("YYYY-MM-DD"),
                duration: dbDuration,
                task: task.replace(/\s{2,}/g, ' ').trim(),
                description: description.replace(/\s{2,}/g, ' ').trim()
            },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                }).then(response => {
                    if (response.status === 201 || response.status === 200) {
                        onUpdated()
                        resetWorklogData()
                    }
                }).catch(error => {
                    openSnackBar(i18n.t("worklogModal.errorWorklogEdit"), "error")
                }
                )
        }
    }

    const getUsersAndProjects = () => {
        axios.get(getUsers, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackBar(i18n.t("worklogModal.generalError"), "error")
            } else {
                setUsers(response.data.filter(user => !user.endDate))
            }
        })

        axios.get(getPrimaryProjects, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            params: { userId: JSON.parse(localStorage.getItem("user")).role !== 1 ? JSON.parse(localStorage.getItem("user")).id : null }
        }).then(response => {
            if (!response.data.length) {
                openSnackBar(i18n.t("worklogModal.generalError"), "error")
            } else {
                setProjects(response.data)
            }
        })
    }

    const renderUserValues = selected => {
        if (selected) {
            let shownValues = users.filter(user => selected.includes(user.id)).map(user => { return user.name })
            return [...shownValues].join(", ")
        }
    }

    const userPicker = () => {
        if (currentUser && currentUser.role === 1) {
            if (id) {
                return (
                    <Autocomplete
                        className={classes.generalField}
                        options={users}
                        value={consistenceUser[0]}
                        onChange={handleEditUserChange}
                        getOptionSelected={(option) => option.id === consistenceUser.id}
                        getOptionLabel={(option) => (option.name)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={i18n.t("worklogModal.user")} />
                        )}
                    />
                )
            } else {
                return (
                    <>
                        <Autocomplete
                            multiple
                            className={classes.select}
                            disableCloseOnSelect
                            options={users}
                            value={consistenceUser}
                            onChange={handleUserChange}
                            getOptionLabel={(option) => (option.name)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderTags={option => renderUserValues(userValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={i18n.t("worklogModal.user")}
                                    variant="outlined"
                                />
                            )}
                        />
                        <Typography className={classes.errorText}>
                            {userError}
                        </Typography>
                    </>
                )
            }
        }
    }

    const deleteLastDate = (event) => {
        if (date && date.length && event.keyCode === 8) {
            event.preventDefault()
            setDate(date.slice(0, -1))
        }
    }

    function RenderDatesTextfield({ openCalendar }) {
        return (
            <TextField
                key='dateTextfield'
                label={i18n.t("worklogModal.chooseDate")}
                variant="outlined"
                multiline
                maxRows={3}
                value={date && date.map(object => moment(object).format("DD-MM-YYYY")).join(", ")}
                onClick={openCalendar}
                onChange={(event) => event.target.selectionStart = 1000}
                onFocus={(event) => event.target.selectionStart = 1000}
                onKeyDown={deleteLastDate}
                className={classes.generalField}
                InputLabelProps={{
                    classes: {
                        root: classes.textFieldLabel,
                        focused: classes.focusedLabel
                    },
                }}
            />
        )
    }

    const deleteProject = (event) => {
        if (projectValue && event.keyCode === 8) {
            sessionStorage.removeItem('worklogConsistenceProject')
            sessionStorage.removeItem('worklogProject')
            setProjectValue(null)
            setConsistenceProject(null)
        }
    }

    const onDismissView = () => {
        setOpen(false)
        setHoursError("")
        setMinutesError("")
        setTaskError("")
        setDateError("")
        setUserError("")
        setProjectError("")
        setEmptyTime(false)
    }

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem("user")))
        setTimeout(() => {
            getUsersAndProjects()
        }, 200)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (duration !== ":") {
            setHours(duration.split(":")[0] < 10 ? duration.split(":")[0].substring(1) : duration.split(":")[0])
            setMinutes(duration.split(":")[1] === "00" ? "" : duration.split(":")[1])
        }
    }, [id, duration])

    useImperativeHandle(ref, () => ({
        setOpen,
        setId,
        setDate,
        setProjectValue,
        setUserValue,
        setTask,
        setDescription,
        setDuration,
        setWorklogDate,
        setConsistenceProject,
        setConsistenceUser
    }))

    return (
        <Dialog
            open={open}
            scroll="body"
            maxWidth="lg"
            onClose={() => id ? resetWorklogData() : onDismissView()}
            PaperProps={{
                style: {
                    backgroundColor: Colors.thirdColor
                },
            }} >
            <DialogContent>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Typography className={classes.title}>{id ? i18n.t("worklogModal.editWorklog") : i18n.t("worklogModal.addWorklog")}</Typography>

                    {id ?
                        <>
                            <DatePicker
                                format="DD-MM-YYYY"
                                placeholder="Date range"
                                onChange={handleDateChange}
                                value={date[0]}
                                type="custom"
                                weekStartDayIndex={1}
                                render={<RenderDatesTextfield />}
                            />
                            <Typography className={classes.errorText}>
                                {dateError}
                            </Typography>
                        </>
                        : <>
                            <DatePicker
                                ref={datePickerRef}
                                format="DD-MM-YYYY"
                                multiple={true}
                                plugins={[
                                    <DatePanel onKeyDown={deleteLastDate} />
                                ]}
                                onChange={dateObjects => {
                                    setDate(dateObjects.map(dateObject => dateObject.toDate()))
                                }}
                                value={date}
                                type="custom"
                                weekStartDayIndex={1}
                                render={<RenderDatesTextfield />}
                            />
                            <Typography className={classes.errorText}>
                                {dateError}
                            </Typography>
                        </>
                    }

                    {userPicker()}

                    <Autocomplete
                        onKeyDown={deleteProject}
                        className={classes.generalField}
                        id="checkboxes-tags-demo"
                        options={projects}
                        value={consistenceProject}
                        onChange={handleProjectChange}
                        getOptionSelected={(option) => consistenceProject && option.id === consistenceProject.id}
                        getOptionLabel={(option) => (option.projectName + " - " + option.clientName)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={projectError === "" ? false : true}
                                helperText={projectError}
                                variant="outlined"
                                label={i18n.t("worklogModal.project")} />
                        )}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Radio
                                    color={Colors.firstColor}
                                    className={classes.radioButton}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.projectName} - {option.clientName}
                            </React.Fragment>
                        )}
                    />

                    <div className={classes.generalField} style={{ display: "flex", alignItems: "center" }}>
                        <TextField label={i18n.t("worklogModal.hours")}
                            error={hoursError === "" ? false : true}
                            inputProps={{ maxLength: 2 }}
                            variant="outlined"
                            value={hours}
                            onChange={handleHoursChange}
                            helperText={hoursError}
                            InputLabelProps={{
                                classes: {
                                    root: classes.textFieldLabel,
                                    focused: classes.focusedLabel
                                },
                            }} />
                        <Typography className={classes.time}>{i18n.t("worklogModal.shortHours")}</Typography>
                        <TextField label={i18n.t("worklogModal.minutes")}
                            error={minutesError === "" ? false : true}
                            inputProps={{ maxLength: 2 }}
                            variant="outlined"
                            value={minutes}
                            style={{ marginLeft: 10 }}
                            onChange={handleMinutesChange}
                            helperText={minutesError}
                            InputLabelProps={{
                                classes: {
                                    root: classes.textFieldLabel,
                                    focused: classes.focusedLabel
                                },
                            }} />
                        <Typography className={classes.time}>{i18n.t("worklogModal.shortMinutes")}</Typography>
                    </div>
                    <Typography className={classes.emptyTime} style={{ display: emptyTime ? "block" : "none" }}>{i18n.t("worklogModal.emptyTime")}</Typography>

                    <TextField label={i18n.t("worklogModal.task")}
                        error={taskError === "" ? false : true}
                        variant="outlined"
                        multiline
                        maxRows={5}
                        value={task}
                        onChange={handleTaskChange}
                        helperText={taskError}
                        className={classes.generalField}
                        InputLabelProps={{
                            classes: {
                                root: classes.textFieldLabel,
                                focused: classes.focusedLabel
                            },
                        }}
                    />

                    <TextField label={i18n.t("worklogModal.description")}
                        variant="outlined"
                        multiline
                        maxRows={5}
                        value={description}
                        onChange={handleDescriptionChange}
                        className={classes.generalField}
                        InputLabelProps={{
                            classes: {
                                root: classes.textFieldLabel,
                                focused: classes.focusedLabel
                            },
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.actionButtons}
                    onClick={() => { id ? updateWorklog() : addEntry() }}>
                    {id ? i18n.t("worklogModal.editButton") : i18n.t("worklogModal.addButton")}
                </Button>
                <Button
                    className={classes.actionButtons}
                    onClick={() => onCancel()}>
                    {i18n.t("worklogModal.cancelButton")}
                </Button>
            </DialogActions>
            <SnackBar ref={snackbarRef} />
        </Dialog>
    )
}

export default forwardRef(WorkLogModal)