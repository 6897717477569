import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Button,
  CssBaseline,
  Typography,
  Box,
  Popover,
} from "@material-ui/core";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { useHistory, Route, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";

//internal
import Colors from "../globals/Colors";
import { logoutUser } from "../utils/User";
import useWindowDimensions from "../hooks/LayoutHooks";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: 27,
  },
  toolBar: {
    marginLeft: -12,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    marginLeft: 15,
    fontSize: 20,
    color: Colors.whiteColor,
    textTransform: "none",
  },
  logoutButton: {
    marginLeft: 15,
    fontSize: 20,
    color: Colors.whiteColor,
    textTransform: "none",
    "&:hover": {
      color: Colors.redColor,
    },
  },
  logo: {
    height: 48,
    width: 48,
  },
  title: {
    fontSize: 22,
  },
  link: {
    textDecoration: "none",
    color: Colors.whiteColor,
  },
  iconButton: {
    backgroundColor: Colors.whiteColor,
    color: Colors.firstColor,
    width: 30,
    height: 30,
    "&:hover": {
      backgroundColor: Colors.thirdColor,
    },
  },
  dateButtonIcon: {
    height: 20,
    width: 20,
    color: Colors.firstColor,
  },
}));

export default function AppBarRenderer(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { i18n } = useTranslation();

  const [user, setUser] = useState();
  const [currentTab, setCurrentTab] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const onClick = (route, title) => {
    history.push(route);
    setCurrentTab(title);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getRouteButton = (classes, title, route, isSmall) => (
    <Route
      render={() =>
        isSmall ? (
          <MenuItem
            onClick={() => {
              onClick(route, title);
              setAnchorEl(null);
            }}
            style={{
              backgroundColor:
                title === currentTab ? Colors.secondColor : Colors.whiteColor,
            }}
          >
            <Typography style={{ fontWeight: 500, fontSize: 18 }}>
              {title}
            </Typography>
          </MenuItem>
        ) : (
          <Button
            className={classes.button}
            style={{
              backgroundColor:
                title === currentTab ? Colors.secondColor : Colors.firstColor,
            }}
            onClick={() => onClick(route, title)}
          >
            {title}
          </Button>
        )
      }
    />
  );

  const getLogOutButton = (classes, title, isSmall) => {
    const logOutUser = (history) => {
      logoutUser();
      history.push("/");
      sessionStorage.clear();
      return false;
    };

    return (
      <Route
        render={({ history }) =>
          isSmall ? (
            <MenuItem style={{backgroundColor: 'rgba(255, 0, 0, 0.1)'}} onClick={() => logOutUser(history)}>
              <Typography style={{ fontWeight: 500, fontSize: 18 }}>
                {title}
              </Typography>
            </MenuItem>
          ) : (
            <Button
              className={classes.logoutButton}
              onClick={() => logOutUser()}
            >
              {title}
            </Button>
          )
        }
      />
    );
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    setCurrentTab(
      location.pathname.substring(1) === "Timeoff"
        ? "Time off"
        : location.pathname.substring(1)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routeButtons = (isSmall) => {
    return (
      <>
        {getRouteButton(
          classes,
          i18n.t("appBar.worklogs"),
          "/Worklogs",
          isSmall
        )}
        {user?.role === 1 &&
          getRouteButton(
            classes,
            i18n.t("appBar.reports"),
            "/Reports",
            isSmall
          )}
        {user?.isEmployee === 1 &&
          getRouteButton(
            classes,
            i18n.t("appBar.timeOff"),
            "/Timeoff",
            isSmall
          )}
        {user?.role === 1 &&
          getRouteButton(
            classes,
            i18n.t("appBar.register"),
            "/Register",
            isSmall
          )}
        {(user?.role === 1 || user?.isEmployee === 0) &&
          getRouteButton(
            classes,
            i18n.t("appBar.invoice"),
            "/Invoice",
            isSmall
          )}
        {user?.role === 1 &&
          getRouteButton(
            classes,
            i18n.t("appBar.dashboard"),
            "/Dashboard",
            isSmall
          )}
        {user?.role === 1 &&
          getRouteButton(classes, i18n.t("appBar.logs"), "/Logs", isSmall)}
        {getLogOutButton(classes, i18n.t("appBar.logout"), isSmall)}
      </>
    );
  };

  const getSpreadMenu = () => {
    return routeButtons();
  };

  const getSmallMenu = () => {
    return (
      <>
        <IconButton
          className={classes.iconButton}
          aria-label="menu"
          style={{
            marginRight: 10,
          }}
          onClick={handleMenuClick}
        >
          <MenuIcon className={classes.dateButtonIcon} />
        </IconButton>

        <Popover
          id="simple-menu"
          open={anchorEl}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: "center", horizontal: "left" }}
          transformOrigin={{ vertical: "center", horizontal: "right" }}
          PaperProps={{
            style: {
              backgroundColor: Colors.whiteColor,
              transform: "translateX(-10%) translateY(0%)",
              overflow: "visible",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              "&::before": {
                backgroundColor: Colors.whiteColor,
                content: '""',
                position: "absolute",
                width: 12,
                height: 12,
                top: 9,
                right: -5,
                transform: "rotate(45deg)",
              },
            }}
          />
          {routeButtons(true)}
        </Popover>
      </>
    );
  };

  return (
    <div>
      <CssBaseline />
      <AppBar position="static" style={{ backgroundColor: Colors.firstColor }}>
        <Toolbar className={classes.toolBar}>
          <PhoneIphoneIcon className={classes.logo} />
          <Link
            to="/Worklogs"
            className={classes.link}
            onClick={() => setCurrentTab("Worklogs")}
          >
            <Typography className={classes.title}>
              {i18n.t("appBar.SM")} - {user && user.name}
            </Typography>
          </Link>

          <div style={{ flex: 1 }} />

          {useWindowDimensions().width < 1400
            ? getSmallMenu()
            : getSpreadMenu()}
        </Toolbar>
      </AppBar>

      <Toolbar id="back-to-top-anchor" />
    </div>
  );
}
