import React, { useState, useRef, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import FilterListIcon from '@material-ui/icons/FilterList'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { minWidth } from '../globals/Constants'
import Colors from '../globals/Colors'
import SearchBar from '../components/SearchBar'
import { getUsers, getClients, getProjects, getAsignedProjects } from '../globals/Routes'
import useWindowDimensions from '../hooks/LayoutHooks'
import Snackbar from '../components/Snackbar'
import DashboardFilterModal from '../components/DashboardFilterModal'
import DashboardModal from '../components/DashboardModal'
import DeleteModal from '../components/DeleteModal'

const useStyles = makeStyles({
    root: {
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20
    },
    actionDiv: {
        display: "flex",
        alignItems: "center",
        marginLeft: 20,
        marginRight: 20,
        marginTop: -45
    },
    search: {
        marginRight: 35,
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.firstColor
        }
    },
    buttonIcon: {
        height: 18,
        width: 18,
        color: Colors.whiteColor
    },
    iconButton: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        width: 30,
        height: 30,
        '&:hover': {
            backgroundColor: Colors.secondColor
        },
        '&:disabled': {
            backgroundColor: 'rgba(20, 85, 135, 0.25)'
        }
    },
    addButton: {
        width: 30,
        height: 30,
        marginRight: 16,
        backgroundColor: Colors.firstColor,
        '&:hover': {
            backgroundColor: Colors.secondColor
        },
        '&:disabled': {
            backgroundColor: 'rgba(20, 85, 135, 0.25)'
        },
        marginLeft: 35
    },
    table: {
        width: "100%",
        backgroundColor: Colors.thirdColor,
        borderRadius: 10
    },
    headerTypography: {
        fontWeight: 900,
        fontSize: 16,
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
    },
    noScrollBar: {
        "&::-webkit-scrollbar": {
            scrollbarColor: Colors.firstColor
        }
    },
    normalBorder: {
        borderWidth: 1
    },
    actionsButtonIcon: {
        height: 18,
        width: 18,
        color: Colors.whiteColor
    },
    headerTitle: {
        fontWeight: 900,
        fontSize: 16,
        color: Colors.whiteColor,
    },
    checkbox: {
        '&:hover': {
            backgroundColor: 'transparent !important'
        }
    }
})

const icon = <CheckBoxOutlineBlankIcon fontSize='medium' style={{ color: Colors.firstColor }} />
const checkedIcon = <CheckBoxIcon fontSize='medium' style={{ color: Colors.firstColor }} />

const Dashboard = () => {
    const classes = useStyles()
    const { i18n } = useTranslation()
    const { height } = useWindowDimensions()

    const [showNoRecords, setShowNoRecords] = useState("none")
    const [loading, setLoading] = useState(false)
    const [inserted, setInserted] = useState(false)
    const [search, setSearch] = useState("")
    const [searchData, setSearchData] = useState([])
    const [filteredData, setFilteredData] = useState(JSON.parse(sessionStorage.getItem("managementFilter"))?.id || 4)
    const [clients, setClients] = useState()
    const [projects, setProjects] = useState()
    const [users, setUsers] = useState()
    const [asignedProjects, setAsignedProjects] = useState()

    const snackbarRef = useRef()
    const searchBarRef = useRef()
    const dashboardFilterRef = useRef()
    const dashboardModalRef = useRef()
    const deleteModalRef = useRef()

    const tableContainer = document.getElementById('tableContainer')

    const openSnackbar = (message, severity) => {
        snackbarRef.current.setOpen(true)
        snackbarRef.current.setMessage(message)
        snackbarRef.current.setSeverity(severity)
    }

    const getUser = () => {
        setLoading(true)
        axios.get(getUsers, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackbar(i18n.t("worklogs.deleteError"), 'error')
            } else {
                setUsers(response.data.filter(user => user.role_id !== 1))
            }
        })
        setLoading(false)
    }

    const getClient = () => {
        setLoading(true)
        axios.get(getClients, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackbar(i18n.t("filterModal.errorClients"), "error")
            } else {
                setClients(response.data)
            }
        })
        setLoading(false)
    }

    const getProject = () => {
        setLoading(true)
        axios.get(getProjects, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackbar(i18n.t("worklogs.deleteError"), 'error')
            } else {
                setProjects(response.data)
            }

            setLoading(false)
        })
    }

    const getAsignedProject = () => {
        axios.get(getAsignedProjects, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackbar(i18n.t("worklogs.deleteError"), 'error')
            } else {
                let data = response.data.filter(project => !project.hidden || !project.hiddenClient)
                setAsignedProjects(data)
            }
        })
    }

    const deleteUser = id => {
        axios.delete(getUsers + "?id=" + id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200 || response.status === 201) {
                deleteModalRef.current.setOpen(false)
                openSnackbar(i18n.t("worklogs.deleteSuccess"), "success")
                const deleteUser = users.filter(row => row.id !== id)
                setUsers(deleteUser)
            } else {
                openSnackbar(i18n.t("worklogs.deleteError"), 'error')
            }
        })
    }

    const deleteClient = id => {
        axios.delete(getClients + "?id=" + id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200 || response.status === 201) {
                deleteModalRef.current.setOpen(false)
                openSnackbar(i18n.t("worklogs.deleteSuccess"), "success")
                const deleteClient = clients.filter(row => row.id !== id)
                setClients(deleteClient)
            } else {
                openSnackbar(i18n.t("worklogs.deleteError"), 'error')
            }
        })
    }

    const deleteProject = id => {
        axios.delete(getProjects + "?id=" + id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200 || response.status === 201) {
                deleteModalRef.current.setOpen(false)
                openSnackbar(i18n.t("worklogs.deleteSuccess"), "success")
                const deleteProject = projects.filter(row => row.id !== id)
                setProjects(deleteProject)
            } else {
                openSnackbar(i18n.t("worklogs.deleteError"), 'error')
            }
        })
    }

    const showData = () => {
        return searchData?.map(row => getTableRow(row))
    }

    const openManagementModalForEdit = (row) => {
        const employeeTypes = [{ id: 0, name: 'Collaborator' }, { id: 1, name: 'Employee' }]
        const hiddenTypes = [{ id: 0, name: 'No' }, { id: 1, name: 'Yes' }]
        const dialog = dashboardModalRef.current

        dialog.setId(row.id)
        dialog.setFilteredData(filteredData)
        if (filteredData === 1) {
            dialog.setFirstName(row.name.split(' ')[0])
            dialog.setLastName(row.name.split(' ')[1])
            dialog.setEmployeeType(employeeTypes[row.isEmployee])
            dialog.setHours(row.hours)
            dialog.setStartDate(row.startDate)
            dialog.setEndDate(row.endDate)
        } else if (filteredData === 2) {
            dialog.setName(row.name)
            dialog.setHiddenType(hiddenTypes[row.hidden])
        } else if (filteredData === 3) {
            dialog.setName(row.projectName)
            dialog.setHiddenType(hiddenTypes[row.hidden])
            dialog.setClientValue(clients.filter(client => client.name === row.clientName)[0])
        }

        dialog.setOpen(true)
    }

    const handleDeleteRow = (row) => {
        const user = { id: row.id, name: row.name, email: row.email }
        const client = { id: row.id, name: row.name }
        const project = { id: row.id, name: row.projectName, clientName: row.clientName }

        deleteModalRef.current.setOpen(true)
        if (filteredData === 1) {
            deleteModalRef.current.setSelectedRow({ user: user })
        } else if (filteredData === 2) {
            deleteModalRef.current.setSelectedRow({ client: client })
        } else {
            deleteModalRef.current.setSelectedRow({ project: project })
        }
    }

    const setSearchBasedOnFilter = () => {
        if (filteredData === 1) {
            setSearchData(users)
        } else if (filteredData === 2) {
            setSearchData(clients)
        } else if (filteredData === 3) {
            setSearchData(projects)
        } else (
            setSearchData(projects?.filter(project => !project.hidden))
        )
    }

    const toggleAsignProject = (userId, projectId) => {
        let asignedProject = asignedProjects?.filter(asigned => asigned.user_id === userId && asigned.project_id === projectId)

        if (asignedProject?.length && asignedProject[0]) {
            axios.delete(getAsignedProjects + "?id=" + asignedProject[0].id, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            }).then(response => {
                if (response.status === 200 || response.status === 201) {
                    const deleteProject = asignedProjects.filter(row => row.id !== asignedProject[0].id)
                    setAsignedProjects(deleteProject)
                    openSnackbar('Project unsigned successfuly', "success")
                } else {
                    openSnackbar(i18n.t("worklogs.deleteError"), 'error')
                }
            })
        } else {
            let sqlArray = [[userId, projectId]]

            axios.post(getAsignedProjects, {
                sqlArray: sqlArray
            },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                }
            ).then(response => {
                if (response.status === 201 || response.status === 200) {
                    setInserted(previousValue => !previousValue)
                    openSnackbar('Project asigned successfuly', "success")
                }
            }).catch(error => {
                openSnackbar(i18n.t("worklogModal.errorWorklogAdd"), "error")
            })
        }
    }

    useEffect(() => {
        getUser()
        getClient()
        getProject()
        getAsignedProject()
        /*eslint-disable-next-line */
    }, [])

    useEffect(() => {
        if (filteredData === 1) {
            getUser()
        } else if (filteredData === 2) {
            getClient()
        } else if (filteredData === 3) {
            getProject()
        } else if (filteredData === 4) {
            getProject()
            getAsignedProject()
        }
        /*eslint-disable-next-line */
    }, [inserted])

    useEffect(() => {
        setSearchBasedOnFilter()
        /*eslint-disable-next-line */
    }, [users, clients, projects, filteredData])

    useEffect(() => {
        if (search) {
            if (filteredData === 1) {
                setSearchData(users.filter(user =>
                    user.name.toLowerCase().includes(search.toLowerCase())))
            } else if (filteredData === 2) {
                setSearchData(clients.filter(client =>
                    client.name.toLowerCase().includes(search.toLowerCase())))
            } else {
                setSearchData(projects.filter(project =>
                    project.projectName.toLowerCase().includes(search.toLowerCase())
                    || project.clientName.toLowerCase().includes(search.toLowerCase())))
            }
        } else {
            setSearchBasedOnFilter()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    useEffect(() => {
        !searchData?.length ? setShowNoRecords('flex') : setShowNoRecords('none')
    }, [searchData])

    const getTableRow = (row) => {
        const employeeType = row.isEmployee === 0 ? 'Collaborator' : 'Employee'
        const formatedHidden = row.hidden === 0 ? 'No' : 'Yes'
        return (
            filteredData !== 4 ?
                <TableRow key={row.id} style={{ backgroundColor: row.status === 0 && Colors.whiteColor }}>
                    <TableCell className={classes.normalBorder}>
                        <Typography style={{ fontSize: 16 }}>{filteredData === 3 ? row.projectName : row.name}</Typography>
                    </TableCell>
                    <TableCell className={classes.normalBorder}>
                        <Typography noWrap={true} style={{ fontSize: 16 }}>{filteredData === 1 ? row.email : filteredData === 2 ? formatedHidden : row.clientName}</Typography>
                    </TableCell>
                    <TableCell className={classes.normalBorder}>
                        <Typography noWrap={true} style={{ fontSize: 16 }}>{filteredData === 1 ? employeeType : filteredData === 3 ? formatedHidden : null}</Typography>
                    </TableCell>
                    {filteredData === 1 && <TableCell className={classes.normalBorder}>
                        <Typography style={{ fontSize: 16 }}>{`${row.hours === 0 ? '-' : row.hours + 'h'}`}</Typography>
                    </TableCell>}
                    {filteredData === 1 && <TableCell className={classes.normalBorder}>
                        <Typography style={{ fontSize: 16 }}>{new Date(row.startDate).toLocaleDateString().slice(0, 10)}</Typography>
                    </TableCell>}
                    {filteredData === 1 && <TableCell className={classes.normalBorder}>
                        <Typography noWrap={true} style={{ fontSize: 16 }}>{row.endDate ? new Date(row.endDate).toLocaleDateString().slice(0, 10) : '-'}</Typography>
                    </TableCell>}
                    <TableCell className={classes.normalBorder}
                        style={{ width: 1 }}>
                        <IconButton
                            className={classes.iconButton}
                            style={{ marginRight: -5 }}
                            onClick={() => openManagementModalForEdit(row)}
                        >
                            <EditIcon className={classes.actionsButtonIcon} />
                        </IconButton>
                    </TableCell>
                    <TableCell className={classes.normalBorder}
                        style={{ width: 1 }}
                        align="right" >
                        <IconButton
                            className={classes.iconButton}
                            style={{ marginRight: -1 }}
                            onClick={() => handleDeleteRow(row)}
                        >
                            <DeleteIcon className={classes.actionsButtonIcon} />
                        </IconButton>
                    </TableCell>
                </TableRow> :
                (row.hidden || !row.hiddenClient) && <TableRow key={row.id}>
                    <TableCell className={classes.normalBorder}>
                        <Typography style={{ fontSize: 16, fontWeight: 600 }}>{row.clientName + ' - ' + row.projectName}</Typography>
                    </TableCell>

                    {users?.filter(user => !user.endDate).map(user => asignedProjects?.filter(asigned => asigned.user_id === user.id && asigned.project_id === row.id)?.length ?
                        <TableCell key={Math.random()} align="center" className={classes.normalBorder}>
                            <Checkbox
                                disableRipple
                                classes={{ root: classes.checkbox }}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={asignedProjects?.filter(asigned => asigned.user_id === user.id && asigned.project_id === row.id)?.length ? true : false}
                                onChange={() => toggleAsignProject(user.id, row.id)}
                            />
                        </TableCell> :
                        <TableCell key={Math.random()} align="center" className={classes.normalBorder}>
                            <Checkbox
                                disableRipple
                                icon={icon}
                                classes={{ root: classes.checkbox }}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={asignedProjects?.filter(asigned => asigned.user_id === user.id && asigned.project_id === row.id)?.length ? true : false}
                                onChange={() => toggleAsignProject(user.id, row.id)}
                            />
                        </TableCell>
                    )}

                </TableRow>
        )
    }

    return (
        <div style={{ minWidth: minWidth }}>
            <div className={classes.actionDiv}>
                <SearchBar
                    ref={searchBarRef}
                    label={filteredData === 1 ? 'Search by username' : filteredData === 2 ? 'Search by client name' : filteredData === 3 ? 'Search by project name' : 'Search by project name or client name'}
                    className={classes.search}
                    onChange={(value) => setSearch(value)}
                />

                <IconButton
                    className={classes.iconButton}
                    onClick={() => dashboardFilterRef.current.setOpen(true)}>
                    <FilterListIcon className={classes.buttonIcon} />
                </IconButton>

                <DashboardFilterModal
                    ref={dashboardFilterRef}
                    setFilters={value => { setFilteredData(value); setSearch(''); searchBarRef.current.setText(''); setInserted(previousValue => !previousValue) }}
                    clearFilters={() => setFilteredData(4)}
                />

                <IconButton
                    className={classes.addButton}
                    onClick={() => {
                        dashboardModalRef.current.setOpen(true)
                        dashboardModalRef.current.setFilteredData(filteredData)
                    }}
                    style={{ marginRight: tableContainer?.scrollHeight && tableContainer.scrollHeight > tableContainer.clientHeight ? 30 : 15 }}
                    disabled={filteredData === 4}
                >
                    <AddIcon className={classes.buttonIcon} />
                </IconButton>

                <DashboardModal
                    ref={dashboardModalRef}
                    onInserted={() => setInserted(previousValue => !previousValue)}
                />
            </div>

            <div className={classes.root}>
                <TableContainer id="tableContainer" style={{ maxHeight: height - 165, overflow: 'auto', borderRadius: 5 }} className={classes.noScrollBar}>
                    <Table className={classes.table} aria-label="simple table" style={{ tableLayout: "auto", overflow: 'clip' }} stickyHeader size='small'>
                        {filteredData !== 4 ?
                            (<>
                                <TableHead style={{ height: 45 }}>
                                    <TableRow>
                                        <TableCell className={classes.headerTypography} style={{ borderTopLeftRadius: 5 }}>{filteredData === 1 ? 'User' : filteredData === 2 ? 'Client' : 'Project'} name</TableCell>
                                        <TableCell className={classes.headerTypography}>{filteredData === 1 ? 'Email' : filteredData === 2 ? 'Is hidden' : 'Client name'}</TableCell>
                                        <TableCell className={classes.headerTypography}>{filteredData === 1 ? 'User type' : filteredData === 3 ? 'Is hidden' : null}</TableCell>
                                        {filteredData === 1 && <TableCell className={classes.headerTypography}>Hours</TableCell>}
                                        {filteredData === 1 && <TableCell className={classes.headerTypography}>Start date</TableCell>}
                                        {filteredData === 1 && <TableCell className={classes.headerTypography}>End date</TableCell>}
                                        <TableCell className={classes.headerTypography}></TableCell>
                                        <TableCell className={classes.headerTypography} style={{ borderTopRightRadius: 5 }} />
                                    </TableRow>
                                </TableHead>
                            </>) :
                            <TableHead style={{ height: 45 }}>
                                <TableRow style={{ backgroundColor: Colors.firstColor }}>
                                    <TableCell className={classes.headerTypography} >
                                        <Typography noWrap={true} className={classes.headerTitle}>{i18n.t("reports.projects")}</Typography>
                                    </TableCell>
                                    {users?.filter(user => !user.endDate).map(user => <TableCell key={user.id} align="center" className={classes.headerTypography}>
                                        <Typography noWrap={true} className={classes.headerTitle}>{user.name}</Typography>
                                    </TableCell>)}
                                </TableRow>
                            </TableHead>
                        }

                        {!loading &&
                            <TableBody>
                                {!loading && showData()}
                            </TableBody>
                        }
                    </Table>

                    <div style={{ display: showNoRecords, width: "100%", alignItems: 'center', justifyContent: 'center', backgroundColor: Colors.thirdColor, height: 45 }}>
                        <Typography style={{ fontSize: 18, color: Colors.greyColor }}>No data</Typography>
                    </div>
                </TableContainer>
            </div>

            <DeleteModal
                ref={deleteModalRef}
                deleteUser={deleteUser}
                deleteClient={deleteClient}
                deleteProject={deleteProject}
            />

            <Snackbar ref={snackbarRef} />
        </div>
    )
}

export default Dashboard