import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Tooltip, TableFooter } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import TodayIcon from '@material-ui/icons/Today';
import FilterListIcon from '@material-ui/icons/FilterList';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTranslation } from 'react-i18next';

//internal
import FilterModal from '../components/LogsFilterModal';
import SnackBar from '../components/Snackbar';
import SearchBar from '../components/SearchBar';
import Colors from '../globals/Colors';
import { log } from '../globals/Routes';
import { day } from '../helpers/DateHelpers';
import { minWidth } from '../globals/Constants';
import useWindowDimensions from '../hooks/LayoutHooks'

const useStyles = makeStyles({
    root: {
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20
    },
    table: {
        width: "100%",
        backgroundColor: Colors.thirdColor
    },
    headerTypography: {
        fontWeight: 900,
        fontSize: 16,
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
    },
    addButton: {
        width: 30,
        height: 30,
        marginRight: 15,
        backgroundColor: Colors.firstColor,
        '&:hover': {
            backgroundColor: Colors.secondColor
        },
        marginLeft: 35
    },
    actionDiv: {
        display: "flex",
        alignItems: "center",
        marginLeft: 20,
        marginRight: 20,
        marginTop: -45
    },
    buttonIcon: {
        height: 20,
        width: 20,
        color: Colors.whiteColor
    },
    actionsButtonIcon: {
        height: 20,
        width: 20,
        color: Colors.whiteColor
    },
    iconButton: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        width: 30,
        height: 30,
        marginRight: 15,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
    dateIconButton: {
        backgroundColor: Colors.whiteColor,
        color: Colors.firstColor,
        width: 30,
        height: 30,
        textAlign: "center",
        '&:hover': {
            backgroundColor: Colors.thirdColor
        }
    },
    arrowsIcon: {
        height: 25,
        width: 25,
        color: Colors.firstColor
    },
    dateButtonIcon: {
        height: 25,
        width: 25,
        color: Colors.firstColor
    },
    changeDateDiv: {
        bottom: 0,
        position: 'sticky',
        height: 45,
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        verticalAlign: "middle",
        justifyContent: "center",
        alignItems: "center",
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5
    },
    search: {
        marginRight: 35,
        width: "100%",
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.firstColor
        },
    },
    footerTypography: {
        marginLeft: 15,
        fontSize: 16,
        fontWeight: 700
    },
    topBorder: {
        borderTopColor: Colors.firstColor,
        borderTopStyle: 'solid',
        borderTopWidth: 5
    },
    topUserBorder: {
        borderTopColor: Colors.secondColor,
        borderTopStyle: 'solid',
        borderTopWidth: 2
    },
    normalBorder: {
        borderWidth: 1
    }
})

const filtersCurrentDate = new Date().toISOString().slice(0, 10)

export default function Logs() {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const { height } = useWindowDimensions()

    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(new Date(sessionStorage.getItem("logStartDate")) || new Date())

    const [search, setSearch] = useState("")

    const [logList, setLogList] = useState([])
    const [logListSearch, setLogListSearch] = useState([])
    const [logFilters, setLogFilters] = useState(JSON.parse(sessionStorage.getItem("logFilters")) || null)

    const [showNoRecords, setShowNoRecords] = useState("none")

    const filterModalRef = useRef()
    const searchBarRef = useRef()
    const snackbarRef = useRef()

    const tableContainer = document.getElementById('tableContainer')

    const handleWorklogFiltersChange = filters => {
        setLogFilters(filters)
        sessionStorage.setItem("logFilters", JSON.stringify(filters))
    }

    const currentDateOnClick = () => {
        setStartDate(new Date())
        sessionStorage.removeItem("logFilters")
        sessionStorage.setItem("logStartDate", new Date())
        logFilters && setLogFilters()

        const dialog = filterModalRef.current
        dialog.setDate(null)
        dialog.setStartDate(new Date().toISOString().slice(0, 10))
        dialog.setEndDate(new Date().toISOString().slice(0, 10))
        dialog.setPreviousValues()
    }

    const getLogRequest = () => {
        setLoading(true)
        axios.get(log, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            params: { startDate: startDate.toISOString().slice(0, 10), endDate: new Date(startDate.getTime() + day).toISOString().slice(0, 10) }
        }).then(response => {
            if (response.data.length) {
                setLogList(response.data)
                setLogListSearch(response.data)
                setShowNoRecords("none")
                setLoading(false)
            } else {
                setLogList([])
                setLogListSearch([])
                setShowNoRecords("block")
                setLoading(false)
            }

        }).catch(error => {
            snackbarRef.current.setOpen(true)
            snackbarRef.current.setMessage(i18n.t("worklogs.problemFetching"))
            snackbarRef.current.setSeverity("error")
        })
    }

    const getWorklogRequestWithFilters = () => {
        axios.get(log, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            params: { startDate: logFilters.startDate, endDate: logFilters.endDate }
        }).then(response => {
            if (response.data.length) {
                setLogList(response.data)
                setShowNoRecords("none")
            } else {
                setShowNoRecords("block")
                setLogList([])
            }
        }).catch(error => {
            snackbarRef.current.setOpen(true)
            snackbarRef.current.setMessage(i18n.t("worklogs.problemFetching"))
            snackbarRef.current.setSeverity("error")
        })
    }

    const handleNextDay = () => {
        if (logFilters) {
            setLogFilters(
                {
                    startDate: moment(new Date(logFilters.startDate).getTime() + day).format("YYYY-MM-DD"),
                    endDate: moment(new Date(logFilters.endDate).getTime() + day).format("YYYY-MM-DD")
                })
            sessionStorage.setItem("logFilters", JSON.stringify({
                startDate: moment(new Date(logFilters.startDate).getTime() + day).format("YYYY-MM-DD"),
                endDate: moment(new Date(logFilters.endDate).getTime() + day).format("YYYY-MM-DD")
            }))
            setStartDate(new Date(startDate.getTime() - day))
            sessionStorage.setItem("logStartDate", new Date(startDate.getTime() - day))
        } else {
            setStartDate(new Date(startDate.getTime() + day))
            sessionStorage.setItem("logStartDate", new Date(startDate.getTime() + day))
        }
    }

    const handlePreviousDay = () => {
        if (logFilters) {
            setLogFilters(
                {
                    startDate: moment(new Date(logFilters.startDate).getTime() - day).format("YYYY-MM-DD"),
                    endDate: moment(new Date(logFilters.endDate).getTime() - day).format("YYYY-MM-DD")
                })
            sessionStorage.setItem("logFilters", JSON.stringify({
                startDate: moment(new Date(logFilters.startDate).getTime() - day).format("YYYY-MM-DD"),
                endDate: moment(new Date(logFilters.endDate).getTime() - day).format("YYYY-MM-DD")
            }))
            setStartDate(new Date(startDate.getTime() - day))
            sessionStorage.setItem("logStartDate", new Date(startDate.getTime() - day))
        } else {
            setStartDate(new Date(startDate.getTime() - day))
            sessionStorage.setItem("logStartDate", new Date(startDate.getTime() - day))
        }
    }

    const getTableRow = (row, index) => {
        return (
            <TableRow key={row.id}>
                <TableCell className={logFilters && index > 0 && moment(row.date).format("YYYY-MM-DD") !== moment(logListSearch[index - 1].date).format("YYYY-MM-DD") ?
                    classes.topBorder : classes.normalBorder}>
                    <Typography style={{ fontSize: 16 }}>{moment(row.date).format("DD-MM-YYYY h:mm:ss")}</Typography>
                </TableCell>
                <TableCell className={logFilters && index > 0 && moment(row.date).format("YYYY-MM-DD") !== moment(logListSearch[index - 1].date).format("YYYY-MM-DD") ?
                    classes.topBorder : classes.normalBorder}>
                    <Typography style={{ fontSize: 16 }}>{row.description}</Typography>
                </TableCell>
            </TableRow>
        )
    }

    useEffect(() => {
        sessionStorage.setItem('logStartDate', new Date())
    }, [])

    useEffect(() => {
        setLogListSearch(logList)
    }, [logList])

    useEffect(() => {
        if (!logFilters) {
            getLogRequest()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate])

    useEffect(() => {
        if (sessionStorage.getItem("logFilters")) {
            getWorklogRequestWithFilters()
        } else {
            getLogRequest()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLogListSearch(logList.filter(log =>
            log.description.toLowerCase().includes(search.toLowerCase())
        ))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    useEffect(() => {
        !logListSearch.length ? setShowNoRecords('block') : setShowNoRecords('none')
    }, [logListSearch])

    useEffect(() => {
        if (logFilters) {
            getWorklogRequestWithFilters()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logFilters])

    return (
        <div style={{ minWidth: minWidth }}>
            <div className={classes.actionDiv}>

                <SearchBar ref={searchBarRef} label={i18n.t("logs.search")} className={classes.search} onChange={(event) => setSearch(event.target.value)} />

                <IconButton
                    className={classes.iconButton}
                    style={{ marginRight: tableContainer?.scrollHeight && tableContainer.scrollHeight > tableContainer.clientHeight ? 30 : 15 }}
                    onClick={() => { filterModalRef.current.setOpen(true); filterModalRef.current.setLogDate(startDate); sessionStorage.getItem("logFilters") && JSON.parse(sessionStorage.getItem("logFilters")).startDate === JSON.parse(sessionStorage.getItem("logFilters")).endDate && filterModalRef.current.setDate([{ startDate: startDate, endDate: startDate }]) }}>
                    <FilterListIcon className={classes.buttonIcon} />
                </IconButton>
                <FilterModal ref={filterModalRef} setFilters={handleWorklogFiltersChange} clearFilters={() => { setLogFilters(); setShowNoRecords("none"); currentDateOnClick() }} />
            </div>

            <div className={classes.root}>
                <TableContainer id='tableContainer' style={{ borderRadius: 5, maxHeight: height - 165 }}>
                    <Table className={classes.table} aria-label="simple table" style={{ tableLayout: "auto" }} size="small" stickyHeader>
                        <TableHead style={{ height: 45 }}>
                            <TableRow style={{ backgroundColor: Colors.firstColor }}>
                                <TableCell className={classes.headerTypography} style={{ borderTopLeftRadius: 5 }}>{i18n.t("worklogs.date")}</TableCell>
                                <TableCell className={classes.headerTypography} width="90%" style={{ borderTopRightRadius: 5 }}>{i18n.t("worklogs.description")}</TableCell>
                            </TableRow>
                        </TableHead>
                        {!loading &&
                            <TableBody>
                                {logListSearch.length !== 0 && logListSearch.map((row, index) => getTableRow(row, index))}
                            </TableBody>
                        }
                    </Table>

                    <div style={{ display: showNoRecords, width: "100%", textAlign: 'center', alignItems: 'center', justifyContent: 'center', backgroundColor: Colors.thirdColor, height: 45, paddingTop: 7 }}>
                        <Typography style={{ fontSize: 18, color: Colors.greyColor }}>{i18n.t("worklogs.noWorklog")}</Typography>
                    </div>

                    <TableFooter className={classes.changeDateDiv}>
                        <Typography className={classes.footerTypography}>
                            {logFilters && (logFilters.startDate !== logFilters.endDate) ?
                                (moment(logFilters.startDate).format('MMM DD, YYYY') + " - " + moment(logFilters.endDate).format('MMM DD, YYYY')) :
                                (new Date(startDate).getDay() === 6 || new Date(startDate).getDay() === 0 ? moment(startDate).format('MMM DD, YYYY') + " - Weekend" :
                                    logFilters && (new Date(logFilters.startDate).getDay() === 6 || new Date(logFilters.startDate).getDay() === 0) ? moment(logFilters.startDate).format('MMM DD, YYYY') + " - Weekend" :
                                        logFilters ? moment(logFilters.startDate).format('MMM DD, YYYY') :
                                            moment(startDate).format('MMM DD, YYYY'))}
                        </Typography>

                        <div style={{ flex: 1 }} />

                        {logFilters && logFilters.startDate !== logFilters.endDate ? null :
                            (<Tooltip title={i18n.t("worklogs.previousDay")}>
                                <IconButton
                                    className={classes.dateIconButton}
                                    onClick={handlePreviousDay}>
                                    <ChevronLeftIcon className={classes.arrowsIcon} />
                                </IconButton>
                            </Tooltip>)
                        }

                        {(startDate.toISOString().slice(0, 10) !== new Date().toISOString().slice(0, 10)) ||
                            (logFilters && logFilters.startDate !== filtersCurrentDate && logFilters.endDate !== filtersCurrentDate) ?
                            (<Tooltip title={i18n.t("worklogs.currentDate")}>
                                <IconButton
                                    className={classes.dateIconButton}
                                    style={{ marginLeft: 35, marginRight: logFilters && logFilters.startDate !== logFilters.endDate ? 15 : 0 }}
                                    onClick={currentDateOnClick}>
                                    <TodayIcon className={classes.dateButtonIcon} />
                                </IconButton>
                            </Tooltip>) : null
                        }

                        {logFilters && logFilters.startDate !== logFilters.endDate ? null :
                            (<Tooltip title={i18n.t("worklogs.nextDay")}>
                                <IconButton
                                    className={classes.dateIconButton}
                                    style={{ marginLeft: 35, marginRight: 15 }}
                                    onClick={handleNextDay}>
                                    <ChevronRightIcon className={classes.arrowsIcon} />
                                </IconButton>
                            </Tooltip>)
                        }
                    </TableFooter>
                </TableContainer>
            </div>

            <SnackBar ref={snackbarRef} />
        </div >
    )
}