import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  TableFooter,
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useTranslation } from "react-i18next";
import { IoIosCopy, CgCheckO, MdRadioButtonUnchecked } from "react-icons/all";
import { IconContext } from "react-icons/lib";

//internal
import WorkLogModal from "../components/WorkLogModal";
import DeleteModal from "../components/DeleteModal";
import FilterModal from "../components/FilterModal";
import SnackBar from "../components/Snackbar";
import SearchBar from "../components/SearchBar";
import Colors from "../globals/Colors";
import {
  worklog,
  getPrimaryProjects,
  getUsers,
  updateStatus,
} from "../globals/Routes";
import { minWidth } from "../globals/Constants";
import useWindowDimensions from "../hooks/LayoutHooks";
import StatusUpdateModal from "../components/StatusUpdateModal";
import {
  firstDay,
  firstDayOfWeek,
  lastDay,
  lastDayOfWeek,
} from "../helpers/DateHelpers";

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  table: {
    width: "100%",
    backgroundColor: Colors.thirdColor,
    borderRadius: 10,
  },
  headerTypography: {
    fontWeight: 900,
    fontSize: 16,
    backgroundColor: Colors.firstColor,
    color: Colors.whiteColor,
  },
  addButton: {
    width: 30,
    height: 30,
    marginRight: 16,
    backgroundColor: Colors.firstColor,
    "&:hover": {
      backgroundColor: Colors.secondColor,
    },
    marginLeft: 35,
  },
  actionDiv: {
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
    marginRight: 20,
    marginTop: -45,
  },
  buttonIcon: {
    height: 18,
    width: 18,
    color: Colors.whiteColor,
  },
  actionsButtonIcon: {
    height: 18,
    width: 18,
    color: Colors.whiteColor,
  },
  doneIconButton: {
    marginRight: 35,
    backgroundColor: Colors.firstColor,
    color: Colors.whiteColor,
    width: 30,
    height: 30,
    "&:hover": {
      backgroundColor: Colors.secondColor,
    },
  },
  iconButton: {
    backgroundColor: Colors.firstColor,
    color: Colors.whiteColor,
    width: 30,
    height: 30,
    "&:hover": {
      backgroundColor: Colors.secondColor,
    },
    "&:disabled": {
      backgroundColor: "rgba(20, 85, 135, 0.25)",
    },
  },
  dateIconButton: {
    backgroundColor: Colors.whiteColor,
    color: Colors.firstColor,
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: Colors.thirdColor,
    },
  },
  arrowsIcon: {
    height: 25,
    width: 25,
    color: Colors.firstColor,
  },
  dateButtonIcon: {
    height: 25,
    width: 25,
    color: Colors.firstColor,
  },
  changeDateDiv: {
    bottom: 0,
    position: "sticky",
    height: 45,
    backgroundColor: Colors.firstColor,
    color: Colors.whiteColor,
    flex: 1,
    display: "flex",
    flexDirection: "row",
    verticalAlign: "middle",
    justifyContent: "center",
    alignItems: "center",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  search: {
    marginRight: 35,
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: Colors.firstColor,
    },
  },
  footerTypography: {
    fontSize: 16,
    fontWeight: 700,
  },
  topBorder: {
    borderTopColor: Colors.firstColor,
    borderTopStyle: "solid",
    borderTopWidth: 5,
  },
  topUserBorder: {
    borderTopColor: Colors.secondColor,
    borderTopStyle: "solid",
    borderTopWidth: 2,
  },
  normalBorder: {
    borderWidth: 1,
  },
  noScrollBar: {
    "&::-webkit-scrollbar": {
      scrollbarColor: Colors.firstColor,
    },
  },
});

export default function Worklogs() {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [currentUser, setCurrentUser] = useState({});

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    JSON.parse(sessionStorage.getItem("startDate")) || {
      startDate: firstDayOfWeek,
      endDate: lastDayOfWeek,
    }
  );

  const [insertedDate, setInsertedDate] = useState(false);

  const [search, setSearch] = useState("");

  const [totalDuration, setTotalDuration] = useState();
  const [worklogList, setWorklogList] = useState([]);
  const [worklogListSearch, setWorklogListSearch] = useState([]);
  const [worklogFilters, setWorklogFilters] = useState(
    JSON.parse(sessionStorage.getItem("filters")) || null
  );

  const [projects, setProjects] = useState();
  const [users, setUsers] = useState();

  const [showNoRecords, setShowNoRecords] = useState("none");

  const [checkInsertForFilters, setCheckInsertForFilters] = useState(false);

  const [actionDivMargin, setActionDivMargin] = useState(15);

  const workLogModalRef = useRef();
  const filterModalRef = useRef();
  const snackbarRef = useRef();
  const searchBarRef = useRef();
  const deleteModalRef = useRef();
  const statusUpdateModalRef = useRef();

  const { height } = useWindowDimensions();

  const tableContainer = document.getElementById("tableContainer");

  const handleWorklogFiltersChange = (filters) => {
    setWorklogFilters(filters);
    sessionStorage.setItem("filters", JSON.stringify(filters));
    setSearch("");
    searchBarRef.current.setText("");
  };

  const handleInsertedDateChange = (date) => {
    setInsertedDate(date);
  };

  const statutes = [
    { id: 2, title: "All" },
    { id: 1, title: "Confirmed" },
    { id: 0, title: "Pending" },
  ];

  const currentDateOnClick = () => {
    setSearch("");
    searchBarRef.current.setText("");
    setStartDate({ startDate: firstDayOfWeek, endDate: lastDayOfWeek });
    sessionStorage.removeItem("filters");
    sessionStorage.setItem(
      "startDate",
      JSON.stringify({ startDate: firstDayOfWeek, endDate: lastDayOfWeek })
    );
    sessionStorage.removeItem("filteredUsers");
    sessionStorage.removeItem("consistenceFilteredUsers");
    sessionStorage.removeItem("filteredProjects");
    sessionStorage.removeItem("consistenceFilteredProjects");
    sessionStorage.removeItem("filteredClients");
    sessionStorage.removeItem("consistenceFilteredClients");
    sessionStorage.removeItem("filteredDate");
    worklogFilters && setWorklogFilters();

    const dialog = filterModalRef.current;
    dialog.setFilteredUsers([JSON.parse(localStorage.getItem("user")).id]);
    dialog.setProjectValue([]);
    dialog.setClientValue([]);
    dialog.setDate(null);
    dialog.setStartDate(firstDay);
    dialog.setEndDate(lastDay);
    dialog.setConsistenceProject([]);
    dialog.setConsistenceClient([]);
    dialog.setConsistenceFilteredUser([
      JSON.parse(localStorage.getItem("user")),
    ]);
    dialog.setConsistenceStatus(statutes[0]);
  };

  const openSnackbar = (message, severity) => {
    snackbarRef.current.setOpen(true);
    snackbarRef.current.setMessage(message);
    snackbarRef.current.setSeverity(severity);
  };

  const getWorklogRequest = () => {
    setLoading(true);

    axios
      .get(worklog, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          startDate: startDate?.startDate,
          endDate: startDate?.endDate,
          userIds: [JSON.parse(localStorage.getItem("user")).id],
        },
      })
      .then((response) => {
        if (response.data.length) {
          setWorklogList(response.data);
          setTotalDuration(
            response.data.reduce((a, b) => (a = a + b.duration), 0)
          );
          setShowNoRecords("none");
          setLoading(false);
        } else {
          setWorklogList([]);
          setWorklogListSearch([]);
          setShowNoRecords("flex");
          setLoading(false);
        }
      })
      .catch((error) => {
        openSnackbar(i18n.t("worklogs.problemFetching"), "error");
      });
  };

  const getWorklogRequestWithFilters = () => {
    let userIds = [];
    let projectIds = [];
    let clientIds = [];
    let status = [0];

    if (worklogFilters && worklogFilters.filteredUsers.length) {
      userIds = worklogFilters.filteredUsers;
    }

    if (worklogFilters && worklogFilters.projectValue) {
      projectIds = worklogFilters.projectValue;
    }

    if (worklogFilters && worklogFilters.clientValue) {
      clientIds = worklogFilters.clientValue;
    }

    if (worklogFilters && worklogFilters.statusValue) {
      status = worklogFilters.statusValue === 2 ? [0, 1] : [1];
    }

    axios
      .get(worklog, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          startDate: worklogFilters.startDate,
          endDate: worklogFilters.endDate,
          userIds: userIds,
          projectIds: projectIds,
          clientIds: clientIds,
          status: status,
        },
      })
      .then((response) => {
        if (response.data.length) {
          setWorklogList(response.data);
          setTotalDuration(
            response.data.reduce((a, b) => (a = a + b.duration), 0)
          );
          setShowNoRecords("none");
        } else {
          setShowNoRecords("block");
          setWorklogList([]);
        }
      })
      .catch((error) => {
        openSnackbar(i18n.t("worklogs.problemFetching"), "error");
      });
  };

  const deleteWorklog = (id) => {
    axios
      .delete(worklog + "?id=" + id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          deleteModalRef.current.setOpen(false);
          openSnackbar(i18n.t("worklogs.deleteSuccess"), "success");
          const deleteWorklog = worklogList.filter((row) => row.id !== id);
          setWorklogList(deleteWorklog);
          if (worklogListSearch.length === 1) {
            setWorklogList([]);
            setShowNoRecords("block");
          }
        } else {
          openSnackbar(i18n.t("worklogs.deleteError"), "error");
        }
      });
  };

  const handleSelectedRowChange = (
    id,
    date,
    user,
    project,
    client,
    duration,
    task,
    description
  ) => {
    const worklog = {
      id: id,
      date: date,
      user: user,
      project: project,
      client: client,
      duration: duration,
      task: task,
      description: description,
    };
    deleteModalRef.current.setOpen(true);
    deleteModalRef.current.setSelectedRow({ worklog: worklog });
  };

  const handleStatusUpdate = (row) => {
    statusUpdateModalRef.current.setOpen(true);
    statusUpdateModalRef.current.setSelectedRow(row);
  };

  const handleAllStatusUpdate = () => {
    statusUpdateModalRef.current.setOpen(true);
    statusUpdateModalRef.current.setSelectedRow(
      worklogListSearch.filter((worklog) => worklog.status === 0).length
    );
  };

  const getProjects = () => {
    axios
      .get(getPrimaryProjects, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (!response.data.length) {
          openSnackbar(i18n.t("worklogs.deleteError"), "error");
        } else {
          setProjects(response.data);
        }
      });
  };

  const getUser = () => {
    axios
      .get(getUsers, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (!response.data.length) {
          openSnackbar(i18n.t("worklogs.deleteError"), "error");
        } else {
          setUsers(response.data);
        }
      });
  };

  const updateWorklogStatus = (id, status) => {
    axios
      .patch(
        worklog + "?id=" + id,
        {
          status: status,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data) {
          statusUpdateModalRef.current.setOpen(false);
          !worklogFilters && getWorklogRequest();
          openSnackbar(i18n.t("worklogModal.succesWorklogEdit"), "success");
          setCheckInsertForFilters(!checkInsertForFilters);
        }
      })
      .catch((error) => {
        statusUpdateModalRef.current.setOpen(false);
        openSnackbar(i18n.t("worklogModal.errorWorklogEdit"), "error");
      });
  };

  const updateAllWorklogStatus = () => {
    let ids = worklogListSearch
      .filter((worklog) => worklog.status === 0)
      .map((x) => x.id);

    axios
      .patch(
        updateStatus,
        {
          status: 1,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            ids: ids,
          },
        }
      )
      .then((response) => {
        if (response.data) {
          statusUpdateModalRef.current.setOpen(false);
          !worklogFilters && getWorklogRequest();
          openSnackbar(i18n.t("worklogModal.succesWorklogEdit"), "success");
          setCheckInsertForFilters(!checkInsertForFilters);
        }
      })
      .catch((error) => {
        statusUpdateModalRef.current.setOpen(false);
        openSnackbar(i18n.t("worklogModal.errorWorklogEdit"), "error");
      });
  };

  const timeFloatToString = (time) => {
    return moment
      .utc(moment.duration(time, "h").asMilliseconds())
      .format("HH:mm");
  };

  const openWorklogModalForEdit = (
    id,
    date,
    duration,
    task,
    description,
    projectId,
    userId,
    project,
    clientName
  ) => {
    const dialog = workLogModalRef.current;

    dialog.setId(id);
    dialog.setDuration(timeFloatToString(duration));
    dialog.setDate([date]);
    dialog.setProjectValue(projectId);
    dialog.setUserValue(userId);
    dialog.setConsistenceUser(users.filter((user) => user.id === userId[0]));
    dialog.setTask(task);
    dialog.setDescription(description);
    dialog.setConsistenceProject(
      projects.filter(
        (proj) => proj.projectName === project && proj.clientName === clientName
      )[0]
    );
    dialog.setOpen(true);
  };

  const descriptionFound = worklogListSearch.filter(worklog => worklog.description !== "")?.length > 0

  const getTableRow = (row, index) => {
    return (
      <TableRow
        key={row.id}
        style={{ backgroundColor: row.status === 0 && Colors.whiteColor }}
      >
        <TableCell
          className={
            index > 0 && row.date !== worklogListSearch[index - 1].date
              ? classes.topBorder
              : index > 0 && row.user !== worklogListSearch[index - 1].user
              ? classes.topUserBorder
              : classes.normalBorder
          }
        >
          <Typography style={{ fontSize: 16 }}>
            {new Date(row.date).toLocaleDateString().slice(0, 10)}
          </Typography>
        </TableCell>
        <TableCell
          className={
            index > 0 && row.date !== worklogListSearch[index - 1].date
              ? classes.topBorder
              : index > 0 && row.user !== worklogListSearch[index - 1].user
              ? classes.topUserBorder
              : classes.normalBorder
          }
        >
          <Typography noWrap={true} style={{ fontSize: 16 }}>
            {row.user}
          </Typography>
        </TableCell>
        <TableCell
          className={
            index > 0 && row.date !== worklogListSearch[index - 1].date
              ? classes.topBorder
              : index > 0 && row.user !== worklogListSearch[index - 1].user
              ? classes.topUserBorder
              : classes.normalBorder
          }
        >
          <Typography noWrap={true} style={{ fontSize: 16 }}>
            {row.project}
          </Typography>
        </TableCell>
        <TableCell
          className={
            index > 0 && row.date !== worklogListSearch[index - 1].date
              ? classes.topBorder
              : index > 0 && row.user !== worklogListSearch[index - 1].user
              ? classes.topUserBorder
              : classes.normalBorder
          }
        >
          <Typography style={{ fontSize: 16 }}>{row.duration}h</Typography>
        </TableCell>
        <TableCell
          className={
            index > 0 && row.date !== worklogListSearch[index - 1].date
              ? classes.topBorder
              : index > 0 && row.user !== worklogListSearch[index - 1].user
              ? classes.topUserBorder
              : classes.normalBorder
          }
        >
          <Typography style={{ fontSize: 16 }}>{row.task}</Typography>
        </TableCell>
        {descriptionFound && <TableCell
          className={
            index > 0 && row.date !== worklogListSearch[index - 1].date
              ? classes.topBorder
              : index > 0 && row.user !== worklogListSearch[index - 1].user
              ? classes.topUserBorder
              : classes.normalBorder
          }
        >
          <Typography style={{ fontSize: 16 }}>{row.description}</Typography>
        </TableCell>}
        <TableCell
          className={
            index > 0 && row.date !== worklogListSearch[index - 1].date
              ? classes.topBorder
              : index > 0 && row.user !== worklogListSearch[index - 1].user
              ? classes.topUserBorder
              : classes.normalBorder
          }
          style={{ width: 1 }}
          align="left"
        >
          {currentUser.role === 1 && (
            <IconButton
              className={classes.iconButton}
              style={{ marginRight: -5 }}
              onClick={() => handleStatusUpdate(row)}
            >
              <IconContext.Provider
                value={{ style: { transform: "scale(3)" } }}
              >
                {row.status === 0 ? <MdRadioButtonUnchecked /> : <CgCheckO />}
              </IconContext.Provider>
            </IconButton>
          )}
        </TableCell>
        <TableCell
          className={
            index > 0 && row.date !== worklogListSearch[index - 1].date
              ? classes.topBorder
              : index > 0 && row.user !== worklogListSearch[index - 1].user
              ? classes.topUserBorder
              : classes.normalBorder
          }
          style={{ width: 1 }}
          align="left"
        >
          <IconButton
            className={classes.iconButton}
            style={{ marginRight: -5 }}
            disabled={!row.description}
            onClick={() => {
              navigator.clipboard.writeText(row.description);
              openSnackbar(
                "The description was copied successfully",
                "success"
              );
            }}
          >
            <IconContext.Provider
              value={{ style: { transform: "scale(2.5)" } }}
            >
              <IoIosCopy
                style={{ color: !row.description && Colors.whiteColor }}
              />
            </IconContext.Provider>
          </IconButton>
        </TableCell>
        <TableCell
          className={
            index > 0 && row.date !== worklogListSearch[index - 1].date
              ? classes.topBorder
              : index > 0 && row.user !== worklogListSearch[index - 1].user
              ? classes.topUserBorder
              : classes.normalBorder
          }
          style={{ width: 1 }}
        >
          <IconButton
            className={classes.iconButton}
            style={{ marginRight: -5 }}
            onClick={() =>
              openWorklogModalForEdit(
                row.id,
                row.date,
                row.duration,
                row.task,
                row.description,
                row.project_id,
                [row.user_id],
                row.project,
                row.clientName
              )
            }
            disabled={
              (currentUser.role !== 1 && row.status === 1) || row.endDate
            }
          >
            <EditIcon className={classes.actionsButtonIcon} />
          </IconButton>
        </TableCell>
        <TableCell
          className={
            index > 0 && row.date !== worklogListSearch[index - 1].date
              ? classes.topBorder
              : index > 0 && row.user !== worklogListSearch[index - 1].user
              ? classes.topUserBorder
              : classes.normalBorder
          }
          style={{ width: 1 }}
          align="right"
        >
          <IconButton
            className={classes.iconButton}
            style={{ marginRight: -1 }}
            onClick={() =>
              handleSelectedRowChange(
                row.id,
                row.date,
                row.user,
                row.project,
                row.clientName,
                row.duration,
                row.task,
                row.description
              )
            }
            disabled={currentUser.role !== 1 && row.status === 1}
          >
            <DeleteIcon className={classes.actionsButtonIcon} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  useEffect(() => {
    setWorklogListSearch(worklogList);
  }, [worklogList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setActionDivMargin(
      tableContainer &&
        window.getComputedStyle(tableContainer)["overflow-y"] &&
        tableContainer?.scrollHeight > tableContainer?.clientHeight
        ? 30
        : 15
    );
  });

  useEffect(() => {
    if (!worklogFilters) {
      getWorklogRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    if (insertedDate && !worklogFilters) {
      getWorklogRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertedDate]);

  useEffect(() => {
    if (worklogFilters) {
      getWorklogRequestWithFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInsertForFilters]);

  useEffect(() => {
    setCurrentUser(JSON.parse(localStorage.getItem("user")));
    getUser();
    sessionStorage.setItem("logStartDate", new Date());
    if (sessionStorage.getItem("filters")) {
      getWorklogRequestWithFilters();
    } else {
      getWorklogRequest();
    }

    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      currentUser.role === 1
        ? setWorklogListSearch(
            worklogList.filter(
              (worklog) =>
                worklog.task.toLowerCase().includes(search.toLowerCase()) ||
                worklog.user.toLowerCase().includes(search.toLowerCase()) ||
                worklog.project.toLowerCase().includes(search.toLowerCase()) ||
                worklog.clientName
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                (worklog.description &&
                  worklog.description
                    .toLowerCase()
                    .includes(search.toLowerCase()))
            )
          )
        : setWorklogListSearch(
            worklogList.filter(
              (worklog) =>
                worklog.task.toLowerCase().includes(search.toLowerCase()) ||
                worklog.project.toLowerCase().includes(search.toLowerCase()) ||
                worklog.clientName
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                (worklog.description &&
                  worklog.description
                    .toLowerCase()
                    .includes(search.toLowerCase()))
            )
          );
    } else {
      setWorklogListSearch(worklogList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, worklogList]);

  useEffect(() => {
    !worklogListSearch.length
      ? setShowNoRecords("flex")
      : setShowNoRecords("none");
    setTotalDuration(
      worklogListSearch.reduce((a, b) => (a = a + b.duration), 0)
    );
  }, [worklogListSearch]);

  useEffect(() => {
    if (worklogFilters) {
      getWorklogRequestWithFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worklogFilters]);

  const copyText = () => {
    let strings = worklogListSearch.map(
      (record) => record.task + ": " + record.description
    );
    let stringToCopy = "";

    openSnackbar("Tasks and descriptions were copied successfully", "success");

    navigator.clipboard.writeText(
      [...new Set(strings)]
        .map((record) => stringToCopy + record)
        .sort()
        .join("\n")
    );
  };

  return (
    <div style={{ minWidth: minWidth }}>
      <div className={classes.actionDiv}>
        <SearchBar
          ref={searchBarRef}
          label={
            currentUser.role === 1
              ? i18n.t("worklogs.searchAdmin")
              : i18n.t("worklogs.search")
          }
          className={classes.search}
          onChange={(value) => setSearch(value)}
        />

        {currentUser.role === 1 &&
        worklogListSearch.filter((worklog) => worklog.status === 0).length ? (
          <IconButton
            className={classes.doneIconButton}
            onClick={() => handleAllStatusUpdate()}
          >
            <IconContext.Provider value={{ style: { transform: "scale(3)" } }}>
              <CgCheckO />
            </IconContext.Provider>
          </IconButton>
        ) : null}

        {currentUser.role === 1 && worklogListSearch.length ? (
          <IconButton
            className={classes.doneIconButton}
            onClick={() => copyText()}
          >
            <IconContext.Provider
              value={{ style: { transform: "scale(2.5)" } }}
            >
              <IoIosCopy />
            </IconContext.Provider>
          </IconButton>
        ) : null}

        <IconButton
          className={classes.iconButton}
          onClick={() => {
            filterModalRef.current.setOpen(true);
            filterModalRef.current.setDashboardDate(startDate);
            worklogFilters &&
              filterModalRef.current.setProjectValue(
                worklogFilters.projectValue
              );
          }}
        >
          <FilterListIcon className={classes.buttonIcon} />
        </IconButton>
        <FilterModal
          ref={filterModalRef}
          setFilters={handleWorklogFiltersChange}
          clearFilters={() => {
            setWorklogFilters();
            setShowNoRecords("none");
            currentDateOnClick();
          }}
          currentUser={[currentUser]}
        />

        <IconButton
          className={classes.addButton}
          onClick={() => {
            workLogModalRef.current.setOpen(true);
            workLogModalRef.current.setWorklogDate(startDate);
            setInsertedDate(false);
          }}
          style={{ marginRight: actionDivMargin }}
        >
          <AddIcon className={classes.buttonIcon} />
        </IconButton>

        <WorkLogModal
          ref={workLogModalRef}
          onInserted={() => {
            insertedDate && getWorklogRequest();
            openSnackbar(i18n.t("worklogModal.succesWorklogAdd"), "success");
            setCheckInsertForFilters((prev) => !prev);
            setSearch(searchBarRef.current.text);
          }}
          onUpdated={() => {
            !worklogFilters && getWorklogRequest();
            openSnackbar(i18n.t("worklogModal.succesWorklogEdit"), "success");
            setCheckInsertForFilters((prev) => !prev);
            setSearch(searchBarRef.current.text);
          }}
          insertedDate={handleInsertedDateChange}
        />
      </div>

      <div className={classes.root}>
        <TableContainer
          id="tableContainer"
          style={{ maxHeight: height - 165, borderRadius: 5 }}
          className={classes.noScrollBar}
        >
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ tableLayout: "auto", overflow: "clip" }}
            stickyHeader
            size="small"
          >
            <TableHead style={{ height: 45 }}>
              <TableRow>
                <TableCell
                  className={classes.headerTypography}
                  style={{ borderTopLeftRadius: 5 }}
                >
                  {i18n.t("worklogs.date")}
                </TableCell>
                <TableCell className={classes.headerTypography}>
                  {i18n.t("worklogs.user")}
                </TableCell>
                <TableCell className={classes.headerTypography}>
                  {i18n.t("worklogs.project")}
                </TableCell>
                <TableCell className={classes.headerTypography}>
                  {i18n.t("worklogs.duration")}
                </TableCell>
                <TableCell className={classes.headerTypography} width="60%">
                  {i18n.t("worklogs.task")}
                </TableCell>
                {descriptionFound && <TableCell className={classes.headerTypography} width="40%">
                  {i18n.t("worklogs.description")}
                </TableCell>}
                <TableCell className={classes.headerTypography} />
                <TableCell className={classes.headerTypography} />
                <TableCell className={classes.headerTypography} />
                <TableCell
                  className={classes.headerTypography}
                  style={{ borderTopRightRadius: 5 }}
                />
              </TableRow>
            </TableHead>
            {!loading && (
              <TableBody>
                {currentUser &&
                  worklogListSearch.length !== 0 &&
                  worklogListSearch.map((row, index) =>
                    worklogFilters &&
                    worklogFilters.filteredUsers.map(
                      (value) => value === row.user_id
                    )
                      ? getTableRow(row, index)
                      : currentUser.id === row.user_id &&
                        getTableRow(row, index)
                  )}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: showNoRecords,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: Colors.thirdColor,
              height: 45,
            }}
          >
            <Typography style={{ fontSize: 18, color: Colors.greyColor }}>
              {i18n.t("worklogs.noWorklog")}
            </Typography>
          </div>

          <TableFooter
            className={classes.changeDateDiv}
            style={{ justifyContent: "flex-start" }}
          >
            <Typography
              className={classes.footerTypography}
              style={{ marginLeft: 15, marginRight: 5 }}
            >
              {worklogFilters &&
              worklogFilters.startDate === worklogFilters.endDate
                ? moment(worklogFilters.startDate).format("dddd DD, MMMM, YYYY")
                : worklogFilters &&
                  worklogFilters.startDate !== worklogFilters.endDate
                ? moment(worklogFilters.startDate).format(
                    "dddd DD, MMMM, YYYY"
                  ) +
                  " - " +
                  moment(worklogFilters.endDate).format("dddd DD, MMMM, YYYY")
                : moment(startDate.startDate).format("dddd DD, MMMM, YYYY") +
                  " - " +
                  moment(startDate.endDate).format("dddd DD, MMMM, YYYY")}
            </Typography>

            <Typography
              className={classes.footerTypography}
              style={{
                marginRight: 5,
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {worklogListSearch.length !== 0 && (
                <Typography style={{ fontSize: 30, marginRight: 5 }}>
                  •
                </Typography>
              )}
              {worklogListSearch.length !== 0 &&
                `${worklogListSearch.length} worklogs `}
              {totalDuration !== 0 && (
                <Typography
                  style={{ fontSize: 30, marginLeft: 5, marginRight: 5 }}
                >
                  •
                </Typography>
              )}
              {totalDuration !== 0 && `${totalDuration}h`}
            </Typography>
          </TableFooter>

          <DeleteModal ref={deleteModalRef} deleteWorklog={deleteWorklog} />

          <StatusUpdateModal
            ref={statusUpdateModalRef}
            updateStatus={(id, status) =>
              id === parseInt(id, 10)
                ? status === 0
                  ? updateWorklogStatus(id, 1)
                  : updateWorklogStatus(id, 0)
                : updateAllWorklogStatus()
            }
          />
        </TableContainer>
      </div>

      <SnackBar ref={snackbarRef} />
    </div>
  );
}
