import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Typography, Button, FormControl, InputLabel, InputAdornment, IconButton, OutlinedInput, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import locale from 'date-fns/locale/en-US';
import axios from 'axios';
import moment from 'moment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useTranslation } from 'react-i18next';

//internal
import Colors from '../globals/Colors';
import SnackBar from './Snackbar';
import DateRangeModal from './DateRangeModal';
import { timeoff, getUsers, getTimeOffProjects } from '../globals/Routes';

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        marginTop: 10,
    },
    generalField: {
        marginTop: 15,
        width: 300,
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.firstColor
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: Colors.firstColor,
        },
    },
    time: {
        fontSize: 20,
        marginLeft: 5
    },
    actionButtons: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        fontSize: 14,
        marginTop: 20,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
    textFieldLabel: {
        "&$focusedLabel": {
            color: Colors.firstColor
        },
    },
    focusedLabel: {},
    select: {
        width: "100%",
        marginTop: 35,
        '& .MuiInputLabel-root.Mui-focused': {
            color: Colors.firstColor,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.firstColor
        }
    },
    selectBorder: {
        '&:hover': {
            borderColor: Colors.firstColor,
        },
        '&:before': {
            borderColor: Colors.firstColor,
        },
        '&:after': {
            borderColor: Colors.firstColor,
        }
    },
    rangeError: {
        width: "100%",
        fontSize: 12,
        textAlign: "left",
        color: Colors.redColor
    }
})

if (locale && locale.options) {
    locale.options.weekStartsOn = 1
}

function TimeOffModal({ onInserted, onUpdated }, ref) {
    const classes = useStyles()

    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)

    const [currentUser, setCurrentUser] = useState()

    const [id, setId] = useState(null)

    const [startDate, setStartDate] = useState(null)

    const [endDate, setEndDate] = useState(null)

    const [userValue, setUserValue] = useState(JSON.parse(sessionStorage.getItem('timeoffUser')) || null)
    const [consistenceUser, setConsistenceUser] = useState(JSON.parse(sessionStorage.getItem('timeoffConsistenceUser')) || null)
    const [userError, setUserError] = useState("")
    const [users, setUsers] = useState([])

    const [projects, setProjects] = useState([])
    const [projectValue, setProjectValue] = useState(JSON.parse(sessionStorage.getItem('timeoffProject')) || null)
    const [consistenceProject, setConsistenceProject] = useState(JSON.parse(sessionStorage.getItem('timeoffConsistenceProject')) || null)
    const [projectError, setProjectError] = useState("")

    const [reason, setReason] = useState(JSON.parse(sessionStorage.getItem('timeoffReason')) || null)
    const [consistenceReason, setConsistenceReason] = useState(JSON.parse(sessionStorage.getItem('timeoffConsistenceReason')) || null)

    const [rangeError, setRangeError] = useState(false)

    const snackbarRef = useRef()
    const dateRangeModalRef = useRef()

    const handleDates = date => {
        setStartDate(date.startDate)
        setEndDate(date.endDate)
    }

    const handleDateChange = date => {
        date.map(date =>
            handleDates(date)
        )
    }

    const handleUserChange = (event, newValue) => {
        if (newValue != null) {
            setUserValue(newValue.id)
            setConsistenceUser(newValue)
            !id && sessionStorage.setItem('timeoffConsistenceUser', JSON.stringify(newValue))
            !id && sessionStorage.setItem("timeoffUser", JSON.stringify(newValue.id))
        }
    }

    const handleProjectChange = (event, newValue) => {
        if (newValue != null) {
            setProjectValue(newValue.id)
            setConsistenceProject(newValue)
            !id && sessionStorage.setItem('timeoffConsistenceProject', JSON.stringify(newValue))
            !id && sessionStorage.setItem("timeoffProject", JSON.stringify(newValue.id))
        }
    }

    const handleReasonChange = (event) => {
        setReason(event.target.value)
        setConsistenceReason(event.target.value)
        !id && sessionStorage.setItem('timeoffReason', JSON.stringify(event.target.value))
        !id && sessionStorage.setItem('timeoffConsistenceReason', JSON.stringify(event.target.value))
    }

    const resetWorklogData = () => {
        setOpen(false)
        setTimeout(() => {
            setId(null)
            setStartDate(null)
            setEndDate(null)
            setUserValue()
            setConsistenceUser()
            setProjectValue()
            setConsistenceProject()
            setReason()
            setConsistenceReason()
            setRangeError(false)
            sessionStorage.removeItem('timeoffUser')
            sessionStorage.removeItem('timeoffConsistenceUser')
            sessionStorage.removeItem('timeoffProject')
            sessionStorage.removeItem('timeoffConsistenceProject')
            sessionStorage.removeItem('timeoffReason')
            sessionStorage.removeItem('timeoffConsistenceReason')
        }, 100)
    }

    const onDismissView = () => {
        setOpen(false)
    }

    const openSnackBar = (message, severity = "success") => {
        snackbarRef.current.setOpen(true)
        snackbarRef.current.setMessage(message)
        snackbarRef.current.setSeverity(severity)
    }

    const validateUser = () => {
        if (!userValue) {
            setUserError("User can't be empty")
            return false
        } else {
            setUserError("")
            return true
        }
    }

    const validateProject = () => {
        if (!projectValue) {
            setProjectError(i18n.t("worklogModal.chooseProject"))
            return false
        } else {
            setProjectError("")
            return true
        }
    }

    const validate = () => {
        if (validateUser() &&
            validateProject()) {
            return true
        }
        return false
    }

    const addEntry = () => {
        if (validate()) {
            axios.post(timeoff, {
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD"),
                userId: userValue,
                projectId: projectValue,
                reason: reason
            },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                }
            ).then(response => {
                if (response.status === 201 || response.status === 200) {
                    onInserted()
                    resetWorklogData()
                }
            }).catch(error => {
                setRangeError(true)
            }
            )
        }
    }

    const getUsersAndProjects = () => {
        axios.get(getUsers, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackBar(i18n.t("timeoffModal.generalError"), "error")
            } else {
                setUsers(response.data.filter(user => !user.endDate))
            }
        })

        axios.get(getTimeOffProjects, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackBar(i18n.t("timeoffModal.generalError"), "error")
            } else {
                setProjects(response.data)
            }
        })
    }

    const updateTimeoff = () => {
        axios.patch(timeoff + "?id=" + id, {
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            user_id: userValue,
            project_id: projectValue,
            reason: reason
        },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
            }).then(response => {
                if (response.status === 201 || response.status === 200) {
                    onUpdated()
                    resetWorklogData()
                }
            }).catch(error => {
                openSnackBar(i18n.t("timeoffModal.errorTimeoffEdit"), "error")
            })
    }

    useEffect(() => {
        getUsersAndProjects()
        setCurrentUser(JSON.parse(localStorage.getItem("user")))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (currentUser) {
            setUserValue(currentUser.id)
        }
    }, [currentUser])

    useImperativeHandle(ref, () => ({
        setOpen,
        setId,
        setStartDate,
        setEndDate,
        setProjectValue,
        setUserValue,
        setReason,
        setConsistenceUser,
        setConsistenceProject,
        setConsistenceReason
    }))

    return (
        <Dialog
            open={open}
            scroll="body"
            maxWidth="lg"
            onClose={() => id ? resetWorklogData() : onDismissView()}
            PaperProps={{
                style: {
                    backgroundColor: Colors.thirdColor
                },
            }} >
            <DialogContent>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Typography className={classes.title}>{id ? i18n.t("timeoffModal.editTimeoff") : i18n.t("timeoffModal.addTimeoff")}</Typography>

                    <FormControl
                        className={classes.select}
                        variant="outlined"
                        onClick={() => {
                            dateRangeModalRef.current.setOpen(true)
                        }}>
                        <InputLabel htmlFor="outlined-adornment-password">{i18n.t("timeoffModal.dateRange")}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type="text"
                            contentEditable={false}
                            value={startDate ? moment(startDate).format('MMM DD, YYYY') + " - " + moment(endDate).format('MMM DD, YYYY') : ''}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        disableRipple={true}
                                        className={classes.dateButton}
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            dateRangeModalRef.current.setOpen(true)
                                        }}
                                    >
                                        <DateRangeIcon
                                            style={{ width: 25, height: 25 }} />
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={82}
                        />
                    </FormControl>
                    <Typography className={classes.rangeError} style={{ display: rangeError ? "block" : "none" }}> {i18n.t("timeoffModal.rangeError")} </Typography>

                    <DateRangeModal ref={dateRangeModalRef} onChangeDate={handleDateChange} />

                    {currentUser && currentUser.role === 1 &&
                        <>
                            <Autocomplete
                                className={classes.generalField}
                                options={users}
                                value={consistenceUser}
                                onChange={handleUserChange}
                                disableClearable
                                getOptionLabel={(option) => (option.name)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={i18n.t("timeoffModal.user")} />
                                )}
                            />
                            <Typography className={classes.rangeError} > {userError} </Typography>
                        </>
                    }

                    <Autocomplete
                        className={classes.generalField}
                        options={projects}
                        value={consistenceProject}
                        onChange={handleProjectChange}
                        disableClearable
                        getOptionLabel={(option) => option.projectName}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={i18n.t("timeoffModal.project")} />
                        )}
                    />
                    <Typography className={classes.rangeError} > {projectError} </Typography>

                    {projectValue === 23 && <TextField label={'Reason'}
                        variant="outlined"
                        multiline
                        maxRows={2}
                        value={consistenceReason}
                        onChange={handleReasonChange}
                        className={classes.generalField}
                        InputLabelProps={{
                            classes: {
                                root: classes.textFieldLabel,
                                focused: classes.focusedLabel
                            }
                        }}
                    />
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.actionButtons}
                    onClick={() => { id ? updateTimeoff() : addEntry() }}
                >
                    {id ? i18n.t("timeoffModal.editButton") : i18n.t("timeoffModal.addButton")}
                </Button>
                <Button
                    className={classes.actionButtons}
                    onClick={() => resetWorklogData()}>
                    {i18n.t("timeoffModal.cancelButton")}
                </Button>
            </DialogActions>
            <SnackBar ref={snackbarRef} />
        </Dialog>
    )
}

export default forwardRef(TimeOffModal)