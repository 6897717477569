import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Dialog, Button, DialogContent, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

//internal
import Colors from '../globals/Colors';

const useStyles = makeStyles({
    dialogTypography: {
        fontSize: 20,
        textAlign: 'center'
    },
    dialogRecord: {
        fontSize: 18,
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    dialogButtons: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        fontSize: 14,
        marginTop: 20,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
})

function DeleteTimeoffModal({ deleteTimeoff }, ref) {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)

    const [selectedRow, setSelectedRow] = useState()

    useImperativeHandle(ref, () => ({
        setOpen,
        setSelectedRow
    }))

    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false) }}
            PaperProps={{
                style: {
                    backgroundColor: Colors.thirdColor
                }
            }}>
            <DialogContent style={{ flexDirection: "column" }}>
                <Typography className={classes.dialogTypography}>
                    {i18n.t("worklogs.deleteRecord")}
                </Typography>

                <Typography className={classes.dialogRecord}>
                    {selectedRow && `${moment(selectedRow.startDate).format('MMM DD, YYYY')} - ${moment(selectedRow.endDate).format('MMM DD, YYYY')}, ${selectedRow.userName}, ${selectedRow.projectName}`}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button className={classes.dialogButtons} onClick={() => { setOpen(false) }}>
                    {i18n.t("worklogs.cancel")}
                </Button>
                <Button className={classes.dialogButtons} onClick={() => deleteTimeoff(selectedRow.id)}>
                    {i18n.t("worklogs.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default forwardRef(DeleteTimeoffModal)