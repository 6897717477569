import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, TableFooter } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { pdf } from "@react-pdf/renderer";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';

//internal
import SnackBar from '../components/Snackbar';
import SearchBar from '../components/SearchBar';
import Colors from '../globals/Colors';
import TimeOffModal from '../components/TimeOffModal';
import TimeOffFilterModal from '../components/TimeOffFilterModal';
import TimeOffRequestPDF from '../components/TimeOffRequestPDF';
import { timeoff, getUsers, getTimeOffProjects } from '../globals/Routes';
import { workingDaysBetweenDates } from '../helpers/DateHelpers';
import { minWidth } from '../globals/Constants';
import useWindowDimensions from '../hooks/LayoutHooks';
import DeleteTimeoffModal from '../components/DeleteTimeoffModal';

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20
  },
  table: {
    width: "100%",
    backgroundColor: Colors.thirdColor
  },
  headerTypography: {
    fontWeight: 900,
    fontSize: 16,
    backgroundColor: Colors.firstColor,
    color: Colors.whiteColor,
  },
  addButton: {
    width: 30,
    height: 30,
    marginRight: 16,
    backgroundColor: Colors.firstColor,
    '&:hover': {
      backgroundColor: Colors.secondColor
    },
    marginLeft: 40
  },
  actionDiv: {
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
    marginRight: 20,
    marginTop: -45
  },
  buttonIcon: {
    height: 18,
    width: 18,
    color: Colors.whiteColor
  },
  iconButton: {
    backgroundColor: Colors.firstColor,
    color: Colors.whiteColor,
    width: 30,
    height: 30,
    '&:hover': {
      backgroundColor: Colors.secondColor
    }
  },
  dateIconButton: {
    backgroundColor: Colors.whiteColor,
    color: Colors.firstColor,
    width: 30,
    height: 30,
    textAlign: "center",
    '&:hover': {
      backgroundColor: Colors.thirdColor
    }
  },
  arrowsIcon: {
    height: 25,
    width: 25,
    color: Colors.firstColor
  },
  dateButtonIcon: {
    height: 25,
    width: 25,
    color: Colors.firstColor
  },
  changeDateDiv: {
    bottom: 0,
    position: 'sticky',
    height: 45,
    backgroundColor: Colors.firstColor,
    color: Colors.whiteColor,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    verticalAlign: "middle",
    justifyContent: "center",
    alignItems: "center",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  search: {
    marginRight: 35,
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: Colors.firstColor
    },
  },
  footerTypography: {
    marginLeft: 15,
    fontSize: 18,
    fontWeight: 700
  },
  topUserBorder: {
    borderTopColor: Colors.firstColor,
    borderTopStyle: 'solid',
    borderTopWidth: "2px"
  }
})

export default function TimeOff() {
  const classes = useStyles()
  const { i18n } = useTranslation()

  const { height } = useWindowDimensions()

  const [users, setUsers] = useState()
  const [projects, setProjects] = useState()

  const [currentUser, setCurrentUser] = useState({})

  //eslint-disable-next-line
  const [startDate, setStartDate] = useState(parseInt(sessionStorage.getItem("startYear")) || new Date().getFullYear())

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")

  const [timeoffList, setTimeoffList] = useState([])
  const [timeoffListSearch, setTimeoffListSearch] = useState([])
  const [timeoffFilters, setTimeoffFilters] = useState(JSON.parse(sessionStorage.getItem("filtersYear")) || null)

  const [showNoRecords, setShowNoRecords] = useState("none")

  const searchBarRef = useRef()
  const timeoffModalRef = useRef()
  const timeoffFilterModalRef = useRef()
  const snackbarRef = useRef()
  const deleteModalRef = useRef()

  const tableContainer = document.getElementById('tableContainer')

  const handleTimeoffFiltersChange = (filters) => {
    setTimeoffFilters(filters)
    sessionStorage.setItem("filtersYear", JSON.stringify(filters))
  }

  const openSnackbar = (message, severity) => {
    snackbarRef.current.setOpen(true)
    snackbarRef.current.setMessage(message)
    snackbarRef.current.setSeverity(severity)
  }

  const getTimeoffRequest = () => {
    setLoading(true)
    axios.get(timeoff, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      params: { startDate: new Date(startDate, 0, 1).toISOString().slice(0, 10), endDate: new Date(startDate, 11, 31).toISOString().slice(0, 10) }
    }).then(response => {
      if (response.data.length) {
        setTimeoffList(response.data)
        setTimeoffListSearch(response.data)
        setShowNoRecords("none")
        setLoading(false)
      } else {
        setTimeoffList([])
        setTimeoffListSearch([])
        setShowNoRecords("block")
        setLoading(false)
      }
    }).catch(error => {
      openSnackbar(i18n.t("timeoff.problemFetching"), "error")
    })
  }

  const getTimeoffRequestWithFilters = () => {
    let userIds = []
    let projectIds = []

    if (timeoffFilters && timeoffFilters.userValue) {
      userIds = timeoffFilters.userValue
    }

    if (timeoffFilters && timeoffFilters.projectValue) {
      projectIds = timeoffFilters.projectValue
    }

    axios.get(timeoff, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      params: { startDate: timeoffFilters.startDate, endDate: timeoffFilters.endDate, userIds: userIds, projectIds: projectIds }
    }).then(response => {
      if (response.data.length) {
        setTimeoffList(response.data)
        setShowNoRecords("none")
      } else {
        setShowNoRecords("block")
        setTimeoffList([])
      }
    }).catch(error => {
      openSnackbar(i18n.t("timeoff.problemFetching"), "error")
    })
  }

  const deleteTimeoff = id => {
    axios.delete(timeoff + "?id=" + id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then(response => {
      if (response.status === 200) {
        deleteModalRef.current.setOpen(false)
        openSnackbar(i18n.t("timeoff.deleteSuccess"), "success")
        const deleteTimeoff = timeoffList.filter(row => row.id !== id)
        setTimeoffList(deleteTimeoff)
        if (timeoffListSearch.length === 1) {
          setTimeoffList([])
          setShowNoRecords("block")
        }
      } else {
        openSnackbar(i18n.t("timeoff.deleteError"), "error")
      }
    })
  }

  const getUsersAndProjects = () => {
    axios.get(getUsers, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then(response => {
      if (!response.data.length) {
        openSnackbar(i18n.t("timeoffModal.generalError"), "error")
      } else {
        setUsers(response.data)
      }
    })

    axios.get(getTimeOffProjects, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then(response => {
      if (!response.data.length) {
        openSnackbar(i18n.t("timeoffModal.generalError"), "error")
      } else {
        setProjects(response.data)
      }
    })
  }

  const openTimeoffModalForEdit = (id, startDate, endDate, userId, projectId, reason) => {
    const dialog = timeoffModalRef.current
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD")
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD")

    dialog.setId(id)
    dialog.setStartDate(formattedStartDate)
    dialog.setEndDate(formattedEndDate)
    dialog.setProjectValue(projectId)
    dialog.setConsistenceUser(users.filter(user => user.id === userId)[0])
    dialog.setUserValue(userId)
    dialog.setConsistenceProject(projects.filter(proj => proj.id === projectId)[0])
    dialog.setReason(reason)
    dialog.setConsistenceReason(reason)
    dialog.setOpen(true)
  }

  const LazyDownloadPDFButton = (user, type, startDate, endDate, reason) => (
    <IconButton
      className={classes.iconButton}
      onClick={async () => {
        const doc = <TimeOffRequestPDF
          user={user}
          days={workingDaysBetweenDates(new Date(startDate), new Date(endDate))}
          type={type === 19 ? i18n.t("timeoff.CP") :
            type === 20 ? i18n.t("timeoff.CM") :
              type === 21 ? i18n.t("timeoff.CFP") :
                i18n.t("timeoff.CZS")}
          startDate={startDate}
          endDate={endDate}
          reason={reason}
        />

        const asPdf = pdf()
        asPdf.updateContainer(doc)
        const blob = await asPdf.toBlob()
        saveAs(blob, `Time off ${user} ${startDate === endDate ? `${startDate}` : `between ${startDate} and ${endDate}`}.pdf`)
      }}
    >
      <GetAppIcon className={classes.buttonIcon} />
    </IconButton>
  )

  const getTableRow = (index, row) => {
    return (
      <TableRow key={row.id}>
        <TableCell classes={{ root: timeoffFilters && index > 0 && row.userId !== timeoffListSearch[index - 1].userId && classes.topUserBorder }} >
          <Typography noWrap={true} style={{ fontSize: 16 }}>{moment(row.startDate).format("MMM DD, YYYY")} - {moment(row.endDate).format("MMM DD, YYYY")}</Typography>
        </TableCell>
        <TableCell className={timeoffFilters && index > 0 && row.userName !== timeoffListSearch[index - 1].userName ? classes.topUserBorder : null}>
          <Typography noWrap={true} style={{ fontSize: 16 }}>{row.userName}</Typography>
        </TableCell>
        <TableCell className={timeoffFilters && index > 0 && row.userName !== timeoffListSearch[index - 1].userName ? classes.topUserBorder : null}>
          <Typography noWrap={true} style={{ fontSize: 16 }}>{row.projectName}</Typography>
        </TableCell>
        <TableCell className={timeoffFilters && index > 0 && row.userName !== timeoffListSearch[index - 1].userName ? classes.topUserBorder : null}>
          <Typography style={{ fontSize: 16 }}>{workingDaysBetweenDates(new Date(row.startDate), new Date(row.endDate)) === 0 ? 1 : workingDaysBetweenDates(new Date(row.startDate), new Date(row.endDate))} days</Typography>
        </TableCell>
        <TableCell className={timeoffFilters && index > 0 && row.userName !== timeoffListSearch[index - 1].userName ? classes.topUserBorder : null} align="center" style={{ marginRight: -6 }}>
          {LazyDownloadPDFButton(row.userName, row.projectId, moment(row.startDate).format('MMM DD, YYYY'), moment(row.endDate).format('MMM DD, YYYY'), row.reason)}
        </TableCell>
        <TableCell className={timeoffFilters && index > 0 && row.userName !== timeoffListSearch[index - 1].userName ? classes.topUserBorder : null} align="center">
          <IconButton
            className={classes.iconButton}
            style={{ marginRight: -5 }}
            onClick={() => openTimeoffModalForEdit(row.id, row.startDate, row.endDate, row.userId, row.projectId, row.reason)}
          >
            <EditIcon className={classes.buttonIcon} />
          </IconButton>
        </TableCell>
        <TableCell className={timeoffFilters && index > 0 && row.userName !== timeoffListSearch[index - 1].userName ? classes.topUserBorder : null} align="center">
          <IconButton
            className={classes.iconButton}
            onClick={() => { deleteModalRef.current.setSelectedRow(row); deleteModalRef.current.setOpen(true) }}
          >
            <DeleteIcon className={classes.buttonIcon} />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    setCurrentUser(JSON.parse(localStorage.getItem("user")))
    getUsersAndProjects()
    if (timeoffFilters) {
      getTimeoffRequestWithFilters()
    } else {
      getTimeoffRequest()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (timeoffFilters) {
      getTimeoffRequestWithFilters()
    } else {
      getTimeoffRequest()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate])

  useEffect(() => {
    setTimeoffListSearch(timeoffList)
  }, [timeoffList])

  useEffect(() => {
    setTimeoffListSearch(timeoffList.filter(time =>
      time.userName.toLowerCase().includes(search.toLowerCase())
      || time.projectName.toLowerCase().includes(search.toLowerCase())
    ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    if (timeoffFilters) {
      getTimeoffRequestWithFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoffFilters])

  return (
    <div style={{ minWidth: minWidth }}>
      <div className={classes.actionDiv}>

        <SearchBar
          ref={searchBarRef}
          label={i18n.t("timeoff.search")}
          className={classes.search}
          onChange={(value) => setSearch(value)}
        />

        <IconButton
          className={classes.iconButton}
          style={{ marginRight: -5 }}
          onClick={() => timeoffFilterModalRef.current.setOpen(true)}>
          <FilterListIcon className={classes.buttonIcon} />
        </IconButton>

        <TimeOffFilterModal
          ref={timeoffFilterModalRef}
          setFilters={handleTimeoffFiltersChange}
          clearFilters={() => { getTimeoffRequest(); setTimeoffFilters(); setShowNoRecords("none") }}
        />

        <IconButton
          className={classes.addButton}
          onClick={() => { timeoffModalRef.current.setOpen(true) }}
          style={{ marginRight: tableContainer?.scrollHeight && tableContainer.scrollHeight > tableContainer.clientHeight ? 31 : 16 }}>
          <AddIcon className={classes.buttonIcon} />
        </IconButton>

        <TimeOffModal
          ref={timeoffModalRef}
          onInserted={() => { timeoffFilters ? getTimeoffRequestWithFilters() : getTimeoffRequest(); setShowNoRecords("none"); openSnackbar(i18n.t("timeoff.successTimeoffAdd"), "success") }}
          onUpdated={() => { timeoffFilters ? getTimeoffRequestWithFilters() : getTimeoffRequest(); openSnackbar(i18n.t("timeoff.successTimeoffEdit"), "success") }} />
      </div>

      <div className={classes.root}>
        <TableContainer id="tableContainer" style={{ maxHeight: height - 165, overflow: 'auto', borderRadius: 5 }}>
          <Table className={classes.table} aria-label="simple table" style={{ tableLayout: "auto", overflow: 'clip' }} size="small" stickyHeader>
            <TableHead style={{ height: 45 }}>
              <TableRow style={{ backgroundColor: Colors.firstColor }}>
                <TableCell className={classes.headerTypography} style={{ borderTopLeftRadius: 5 }}>{i18n.t("timeoff.date")}</TableCell>
                <TableCell className={classes.headerTypography}>{i18n.t("timeoff.user")}</TableCell>
                <TableCell className={classes.headerTypography}>{i18n.t("timeoff.project")}</TableCell>
                <TableCell className={classes.headerTypography} style={{ width: "70%" }}>{i18n.t("timeoff.duration")}</TableCell>
                <TableCell className={classes.headerTypography} style={{ width: 50 }} />
                <TableCell className={classes.headerTypography} style={{ width: 60 }} />
                <TableCell className={classes.headerTypography} style={{ width: 40, borderTopRightRadius: 5 }} />
              </TableRow>
            </TableHead>
            {!loading &&
              <TableBody>
                {currentUser && timeoffListSearch.length !== 0 && timeoffListSearch.map((row, index) =>
                  timeoffFilters && timeoffFilters.userValue.map(value => value === row.userId) ? getTableRow(index, row) :
                    currentUser.id === row.userId && (getTableRow(index, row))
                )}
              </TableBody>}
          </Table>

          <div style={{ display: showNoRecords, width: "100%", alignItems: 'center', justifyContent: 'center', backgroundColor: Colors.thirdColor, height: 45, textAlign: 'center' }}>
            <Typography style={{ fontSize: 18, color: Colors.greyColor, paddingTop: 8 }}>{i18n.t("timeoff.noTimeoff")}</Typography>
          </div>

          <TableFooter className={classes.changeDateDiv} style={{ flex: 1, justifyContent: 'flex-start' }}>
            <Typography className={classes.footerTypography}>
              {timeoffFilters && (timeoffFilters.startDate !== timeoffFilters.endDate) ?
                (moment(timeoffFilters.startDate).format('MMM DD, YYYY') + " - " + moment(timeoffFilters.endDate).format('MMM DD, YYYY')) :
                (startDate)}
            </Typography>
          </TableFooter>
        </TableContainer>
      </div>

      <DeleteTimeoffModal ref={deleteModalRef} deleteTimeoff={deleteTimeoff} />
      <SnackBar ref={snackbarRef} />
    </div>
  )
}