import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { DateRangePicker } from 'react-date-range';
import { useTranslation } from 'react-i18next';

//internal
import Colors from '../globals/Colors';

//css
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css';

const useStyles = makeStyles({
    actionButtons: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        fontSize: 14,
        marginTop: 20,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    }
})

const current = new Date()
const first = current.getDate()
const rangeDate = new Date(current.setDate(first))

function DateRangeModal({ onChangeDate }, ref) {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)
    const [date, setDate] = useState([
        {
            startDate: rangeDate,
            endDate: rangeDate,
            key: 'selection'
        }
    ])

    const setDefaultValues = () => {
        setDate([
            {
                startDate: rangeDate,
                endDate: rangeDate,
                key: 'selection'
            }
        ])
    }

    useImperativeHandle(ref, () => ({
        setOpen,
        setDate
    }))

    return (
        <Dialog open={open}
            scroll="body"
            maxWidth="lg"
            onClose={() => setOpen(false)}
            PaperProps={{
                style: {
                    backgroundColor: Colors.thirdColor
                },
            }} >
            <DialogContent>
                <DateRangePicker
                    onChange={item => setDate([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={date}
                    direction="vertical"
                    scroll={{ enabled: true }}
                />
            </DialogContent>
            <DialogActions>
                <Button className={classes.actionButtons} onClick={() => { setOpen(false); onChangeDate(date); setDefaultValues() }}>{i18n.t("dateRangeModal.select")}</Button>
                <Button className={classes.actionButtons} onClick={() => { setOpen(false); setDefaultValues() }}>{i18n.t("dateRangeModal.cancel")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default forwardRef(DateRangeModal)