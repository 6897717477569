import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

//internal
import Colors from '../globals/Colors';

const useStyles = makeStyles({
  textFieldLabel: {
    fontSize: 18,
    "&$focusedLabel": {
      color: Colors.firstColor,
      backgroundColor: Colors.whiteColor,
    },
  },
  focusedLabel: {}
})

function SearchBar({ label, onChange, className }, ref) {
  const classes = useStyles()

  const [text, setText] = useState('')

  useImperativeHandle(ref, () => ({
    text,
    setText
  }))

  const onInputChange = event => {
    if (event.target.value.length === 0) {
      setText('')
      onChange('')
    } else {
      setText(event.target.value)
      onChange(event.target.value)
    }
  }

  return (
    <TextField
      label={label}
      variant="outlined"
      size="small"
      fullWidth
      className={className}
      value={text}
      InputLabelProps={{
        classes: {
          root: classes.textFieldLabel,
          focused: classes.focusedLabel
        }
      }}
      onChange={(event) => onInputChange(event)}
    />
  )
}

export default forwardRef(SearchBar)