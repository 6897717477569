import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Internal
import en from './en.json';

const resources = {
  en:{translation: en},
}

export const resourceList = Object.keys(resources)

const defaultLang = "en"
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLang,

    keySeparator: ".", // We do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // React already safes from xss
    }
  })

export default i18n