const BASE = "https://timetracking.sprintmobile.eu/"

//auth
export const authRoute = BASE + "auth"
export const logoutRoute = BASE + "auth/logout"

//worklog
export const worklog = BASE + "api/worklog"
export const updateStatus = BASE + "api/worklog/updateStatus"
export const excelWorklog = BASE + "api/worklog/excel"

//user
export const getUsers = BASE + "api/user"

//project
export const getProjects = BASE + "api/project"
export const getAsignedProjects = BASE + 'api/project/asigned'
export const getTimeOffProjects = BASE + "api/project/timeOff"
export const getWorklogTimeOff = BASE + "api/project/worklogTimeOff"
export const getPrimaryProjects = BASE + "api/project/primaryProjects"
export const getRegisterTimeOff = BASE + "api/project/registerTimeOff"
export const getHolidayRegister = BASE + "api/project/holidayRegisterTimeOff"

//client
export const getClients = BASE + "api/client"

//reports
export const getReports = BASE + "api/reports"
export const getReportsUsersHours = BASE + "api/reports/usersHours"
export const getReportsTotals = BASE + "api/reports/totals"

//timeoff
export const timeoff = BASE + "api/timeoff"

//log
export const log = BASE + "api/logs"

//invoice
export const invoice = BASE + "api/invoice"