import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Button, Typography, FormControl, InputLabel, Input, IconButton, InputAdornment } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

//internal
import Colors from '../globals/Colors';
import DateRangeModal from './DateRangeModal';

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: 450,
        maxWidth: 450,
        height: 130
    },
    title: {
        fontSize: 22,
        fontWeight: 700
    },
    dateButton: {
        marginRight: -15,
        '&:hover': {
            backgroundColor: "transparent"
        }
    },
    select: {
        width: "100%",
        marginTop: 20,
        '& .MuiInputLabel-root.Mui-focused': {
            color: Colors.firstColor,
        },
    },
    selectBorder: {
        '&:hover': {
            borderColor: Colors.firstColor,
        },
        '&:before': {
            borderColor: Colors.firstColor,
        },
        '&:after': {
            borderColor: Colors.firstColor,
        }
    },
    actionButtons: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        fontSize: 14,
        marginTop: 20,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
    textFieldLabel: {
        "&$focusedLabel": {
            color: Colors.firstColor
        },
    },
    focusedLabel: {}
})

function FilterModal({ setFilters, clearFilters }, ref) {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)

    const [date, setDate] = useState(JSON.parse(sessionStorage.getItem("logFilterDate")) || null)
    const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10))
    const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10))
    const [logDate, setLogDate] = useState()

    const [previousValues, setPreviousValues] = useState()

    const dateRangeModalRef = useRef()

    const handleDateChange = date => {
        setDate(date)
        sessionStorage.setItem("logFilterDate", JSON.stringify(date))
    }

    const setDates = data => {
        setStartDate(transformDate(data.startDate))
        setEndDate(transformDate(data.endDate))
    }


    const resetValues = () => {
        setDate(null)
        sessionStorage.removeItem("logFilterDate")
        sessionStorage.removeItem("logFilters")
        setStartDate(new Date().toISOString().slice(0, 10))
        setEndDate(new Date().toISOString().slice(0, 10))
        setPreviousValues(null)
    }

    const handlePreviousValuesChange = () => {
        setPreviousValues({ startDate: startDate, endDate: endDate })
    }

    const cancelDialog = () => {
        if (previousValues) {
            setStartDate(previousValues.startDate)
            setEndDate(previousValues.endDate)
        }
    }

    const transformDate = value => {
        let date = new Date(value),
            month = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), month, day].join("-");
    }

    useEffect(() => {
        if (date) {
            date.map(data =>
                setDates(data)
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])

    useEffect(() => {
        if (!date && logDate) {
            setStartDate(new Date(logDate).toISOString().slice(0, 10))
            setEndDate(new Date(logDate).toISOString().slice(0, 10))
        }
    }, [date, logDate])

    useImperativeHandle(ref, () => ({
        setOpen,
        setDate,
        setStartDate,
        setEndDate,
        setLogDate,
        setPreviousValues,
    }))

    return (
        <Dialog open={open}
            maxWidth="sm"
            onClose={() => { setOpen(false); cancelDialog() }}
            PaperProps={{
                style: {
                    backgroundColor: Colors.thirdColor
                }
            }} >
            <DialogContent className={classes.root}>
                <Typography className={classes.title}>{i18n.t("logsFilterModal.filterRecords")}</Typography>

                <FormControl className={classes.select}>
                    <InputLabel htmlFor="standard-adornment-password">{i18n.t("filterModal.dateRange")}</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type="text"
                        value={moment(startDate).format('MMM DD, YYYY') + " - " + moment(endDate).format('MMM DD, YYYY')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    disableRipple={true}
                                    className={classes.dateButton}
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        dateRangeModalRef.current.setOpen(true);
                                        dateRangeModalRef.current.setDate([
                                            {
                                                startDate: new Date(startDate),
                                                endDate: new Date(endDate),
                                                key: 'selection'
                                            }
                                        ])
                                    }}
                                >
                                    <DateRangeIcon
                                        style={{ width: 25, height: 25 }} />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <DateRangeModal ref={dateRangeModalRef} onChangeDate={handleDateChange} />
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.actionButtons}
                    onClick={() => { setOpen(false); setFilters({ startDate, endDate }); handlePreviousValuesChange() }}>
                    {i18n.t("filterModal.apply")}
                </Button>
                <Button
                    className={classes.actionButtons}
                    onClick={() => { setOpen(false); resetValues(); clearFilters() }}
                    style={{ marginLeft: 20 }}>
                    {i18n.t("filterModal.clear")}
                </Button>
                <Button
                    className={classes.actionButtons}
                    style={{ marginLeft: 20 }}
                    onClick={() => { setOpen(false); cancelDialog() }}>
                    {i18n.t("filterModal.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default forwardRef(FilterModal)