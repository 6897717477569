import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Dialog, Button, DialogContent, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

//internal
import Colors from '../globals/Colors';

const useStyles = makeStyles({
    dialogTypography: {
        fontSize: 20,
        textAlign: 'center'
    },
    dialogRecord: {
        fontSize: 18,
        marginTop: 20,
        textAlign: 'center'
    },
    dialogButtons: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        fontSize: 14,
        marginTop: 20,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
})

function StatusUpdateModal({ updateStatus }, ref) {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)

    const [selectedRow, setSelectedRow] = useState()

    useImperativeHandle(ref, () => ({
        setOpen,
        setSelectedRow
    }))

    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false) }}
            PaperProps={{
                style: {
                    backgroundColor: Colors.thirdColor
                }
            }}>
            <DialogContent style={{ flexDirection: "column" }}>
                <Typography className={classes.dialogTypography}>
                    {selectedRow && selectedRow.status === 1 ? i18n.t("worklogs.undoStatus") :
                        selectedRow !== parseInt(selectedRow, 10) ? i18n.t("worklogs.updateStatus") :
                            `Are you sure you want to confirm these ${selectedRow} worklogs?`}
                </Typography>

                {selectedRow && selectedRow !== parseInt(selectedRow, 10) && <Typography className={classes.dialogRecord}>
                    {new Date(selectedRow.date).toISOString().slice(0, 10)}, {selectedRow.user}, {selectedRow.project}, {selectedRow.duration}h, {selectedRow.task}{selectedRow.description && ','} {selectedRow.description}
                </Typography>}
            </DialogContent>
            <DialogActions>
                <Button className={classes.dialogButtons} onClick={() => { setOpen(false) }}>
                    {i18n.t("worklogs.cancel")}
                </Button>
                <Button className={classes.dialogButtons} onClick={() => { updateStatus(selectedRow.id, selectedRow.status) }}>
                    {i18n.t("worklogs.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default forwardRef(StatusUpdateModal)