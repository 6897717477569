import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography, CircularProgress } from '@material-ui/core/';
import { useHistory, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//internal
import Colors from '../globals/Colors';
import { loginUser } from '../utils/User';
import { authRoute } from '../globals/Routes';
import { firstDayOfWeek, lastDayOfWeek } from '../helpers/DateHelpers';

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    },
    typography: {
        bottomMargin: "30px",
        color: Colors.firstColor
    },
    username: {
        flex: 1,
        width: "80%",
        marginTop: 20,
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.firstColor
        },
    },
    password: {
        flex: 1,
        width: "80%",
        marginTop: 30,
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.firstColor
        },
    },
    button: {
        flex: 1,
        width: "30%",
        marginTop: 30,
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor
    },
    error: {
        marginTop: 10,
        color: Colors.redColor
    }
})

export default function Login() {
    const { i18n } = useTranslation()
    const history = useHistory()
    const classes = useStyles()

    const [loading, setLoading] = useState(false)

    const [emailError, setEmailError] = useState("")
    const [email, setEmail] = useState("")

    const [passwordError, setPasswordError] = useState("")
    const [password, setPassword] = useState("")

    const handleEmailChange = event => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = event => {
        setPassword(event.target.value)
    }

    const validate = () => {
        if (/^\w+([.-]?\w+)*@sprintmobile\.eu$/.test(email)) {
            setEmailError("")
            return true
        } else {
            setEmailError(i18n.t("login.incorrectUsername"))
            setLoading(false)
            return false
        }
    }

    const requestOptionsUnregister = (object, method) => {
        return {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        }
    }

    const options = requestOptionsUnregister({ email, password }, "POST")
    let status = 200

    async function handleLogin(history) {
        setLoading(true)
        return fetch(authRoute, options)
            .then(response => {
                status = response.status
                return response.json()
            }).then(response => {
                const user = {
                    id: response.id,
                    email: response.email,
                    role: response.role_id,
                    name: response.name,
                    isEmployee: response.isEmployee
                }
                if (status === 200) {
                    loginUser(response.token, user)
                    sessionStorage.setItem("startDate", JSON.stringify({ startDate: firstDayOfWeek, endDate: lastDayOfWeek }))
                    setPasswordError("")
                    setEmailError("")
                    setLoading(false)
                    history.push('/Worklogs')
                } else if (status === 400) {
                    setEmailError(i18n.t("login.incorrectUsername"))
                    setPasswordError("")
                    setLoading(false)
                } else if (status === 401) {
                    setPasswordError(i18n.t("login.incorrectPassword"))
                    setEmailError("")
                    setLoading(false)
                } else if (status === 500) {
                    setLoading(false)
                }
            }).catch(error => {
                setLoading(false)
            })
    }

    const onEnterPressedHandler = (event, history) => {
        if (event.keyCode === 13) {
            if (validate()) {
                handleLogin(history)
            }
        }
    }

    const LoginButton = () => (
        <Route render={({ history }) => (
            <Button
                className={classes.button}
                variant="contained"
                onClick={() => { validate(); handleLogin(history) }}
            >
                {loading ? <CircularProgress style={{ color: Colors.whiteColor }} /> : i18n.t("login.login")}
            </Button>
        )} />
    )

    return (
        <div className={classes.root} onKeyDown={event => onEnterPressedHandler(event, history)}>
            <Typography className={classes.typography}
                component="h3"
                variant="h3"
                align="center"
                gutterBottom>
                {i18n.t("login.SMTT")}
            </Typography>
            <TextField className={classes.username}
                error={emailError === "" ? false : true}
                label={i18n.t("login.username")}
                variant="outlined"
                type="email"
                value={email}
                onChange={handleEmailChange}
                helperText={emailError} />
            <TextField className={classes.password}
                error={passwordError === "" ? false : true}
                label={i18n.t("login.password")}
                variant="outlined"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                helperText={passwordError} />
            {LoginButton(history)}
        </div>
    )
}