import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Typography, Button, TextField, Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import locale from 'date-fns/locale/en-US';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from '@date-io/date-fns'
import 'react-multi-date-picker/styles/colors/teal.css';

//internal
import Colors from '../globals/Colors';
import SnackBar from './Snackbar';
import { getClients, getProjects, getUsers } from '../globals/Routes';

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        marginTop: 10,
    },
    generalField: {
        marginTop: 15,
        width: 400,
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.firstColor
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: Colors.firstColor
        },
    },
    datePickerFocused: {
        borderColor: Colors.firstColor,
        color: Colors.firstColor
    },
    time: {
        fontSize: 20,
        marginLeft: 5
    },
    actionButtons: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        fontSize: 14,
        marginTop: 20,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
    textFieldLabel: {
        "&$focusedLabel": {
            color: Colors.firstColor
        },
    },
    focusedLabel: {},
    emptyTime: {
        color: Colors.redColor,
        fontSize: 12,
        width: "100%",
        textAlign: "left",
        marginLeft: 10
    },
    select: {
        width: "100%",
        marginTop: 20,
        '& .MuiInputLabel-root.Mui-focused': {
            color: Colors.firstColor,
        },
    },
    selectBorder: {
        '&:hover': {
            borderColor: Colors.firstColor,
        },
        '&:before': {
            borderColor: Colors.firstColor,
        },
        '&:after': {
            borderColor: Colors.firstColor,
        }
    },
    datePicker: {
        width: '100%'
    },
    errorText: {
        textAlign: "left",
        width: "100%",
        fontSize: 12,
        marginLeft: 10,
        color: Colors.redColor,
        fontWeight: 500
    },
    radioButton: {
        color: Colors.firstColor,
        '&$checked': {
            color: Colors.firstColor,
        },
    }
})

if (locale && locale.options) {
    locale.options.weekStartsOn = 1
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" style={{ color: Colors.firstColor }} />
const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: Colors.firstColor }} />
const employeeTypes = [{ id: 0, name: 'Collaborator' }, { id: 1, name: 'Employee' }]
const hiddenTypes = [{ id: 0, name: 'No' }, { id: 1, name: 'Yes' }]

function DashboardModal({ onInserted }, ref) {
    const classes = useStyles()

    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)
    const [id, setId] = useState(null)
    const [filteredData, setFilteredData] = useState()

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')

    const [firstName, setFirstName] = useState('')
    const [firstNameError, setFirstNameError] = useState('')

    const [lastName, setLastName] = useState('')
    const [lastNameError, setLastNameError] = useState('')

    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const [employeeType, setEmployeeType] = useState(employeeTypes[1])
    const [hiddenType, setHiddenType] = useState(hiddenTypes[0])

    const [hours, setHours] = useState(8)
    const [hoursError, setHoursError] = useState('')

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [clients, setClients] = useState()
    const [clientValue, setClientValue] = useState()
    const [clientValueError, setClientValueError] = useState('')

    const snackbarRef = useRef()

    const handleNameChange = event => {
        setNameError('')
        setName(event.target.value)
    }

    const handleFirstChange = event => {
        setFirstNameError('')
        setFirstName(event.target.value)
    }

    const handleLastNameChange = event => {
        setLastNameError('')
        setLastName(event.target.value)
    }

    const handlePasswordChange = event => {
        setPasswordError('')
        setPassword(event.target.value)
    }

    const handleEmployeeTypeChange = (event, newValue) => {
        setEmployeeType(newValue)
    }

    const handleHiddenTypeChange = (event, newValue) => {
        setHiddenType(newValue)
    }

    const handleHoursChange = event => {
        setHoursError('')
        setHours(event.target.value)
    }

    const handleStartDateChange = date => {
        setStartDate(date)
    }

    const handleEndDateChange = date => {
        setEndDate(date)
    }

    const handleClientChange = (event, newValue) => {
        setClientValueError('')
        setClientValue(newValue)
    }

    const resetWorklogData = () => {
        setOpen(false)
        setTimeout(() => {
            setId(null)
            setName('')
            setFirstName('')
            setLastName('')
            setPassword('')
            setEmployeeType(employeeTypes[1])
            setHiddenType(hiddenTypes[0])
            setHours(8)
            setStartDate(null)
            setEndDate(null)
            setClientValue('')
        }, 100)
    }

    const openSnackBar = (message, severity = "success") => {
        snackbarRef.current.setOpen(true)
        snackbarRef.current.setMessage(message)
        snackbarRef.current.setSeverity(severity)
    }

    const getClient = () => {
        axios.get(getClients, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackBar(i18n.t("filterModal.errorClients"), "error")
            } else {
                setClients(response.data)
            }
        })
    }

    const addEntry = () => {
        if (filteredData === 1) {
            addUser()
        } else if (filteredData === 2) {
            addClient()
        } else if (filteredData === 3) {
            addProject()
        }
    }

    const updateEntry = () => {
        if (filteredData === 1) {
            updateUser()
        } else if (filteredData === 2) {
            updateClient()
        } else if (filteredData === 3) {
            updateProject()
        }
    }

    const addUser = () => {
        let sqlArray = [[firstName + ' ' + lastName, (firstName.replace(/\s/g, '.').concat('.').concat(lastName).concat('@sprintmobile.eu').toLowerCase()), password, employeeType.id, hours, moment(startDate).format('YYYY-MM-DD'), endDate && moment(endDate).format('YYYY-MM-DD')]]

        axios.post(getUsers, {
            sqlArray: sqlArray
        },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
            }
        ).then(response => {
            if (response.status === 201 || response.status === 200) {
                onInserted()
                resetWorklogData()
            }
        }).catch(error => {
            openSnackBar(i18n.t("worklogModal.errorWorklogAdd"), "error")
        })
    }

    const addClient = () => {
        let sqlArray = [[name, hiddenType.id]]

        axios.post(getClients, {
            sqlArray: sqlArray
        },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
            }
        ).then(response => {
            if (response.status === 201 || response.status === 200) {
                onInserted()
                resetWorklogData()
            }
        }).catch(error => {
            openSnackBar(i18n.t("worklogModal.errorWorklogAdd"), "error")
        })
    }

    const addProject = () => {
        let sqlArray = [[clientValue.id, name, hiddenType.id]]

        axios.post(getProjects, {
            sqlArray: sqlArray
        },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
            }
        ).then(response => {
            if (response.status === 201 || response.status === 200) {
                onInserted()
                resetWorklogData()
            }
        }).catch(error => {
            openSnackBar(i18n.t("worklogModal.errorWorklogAdd"), "error")
        })
    }

    const updateUser = () => {
        axios.patch(getUsers + "?id=" + id, {
            name: firstName + ' ' + lastName,
            email: (firstName.replace(/\s/g, '.').concat('.').concat(lastName).concat('@sprintmobile.eu').toLowerCase()),
            is_employee: employeeType.id,
            hours: hours,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: endDate && moment(endDate).format('YYYY-MM-DD')
        },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
            }).then(response => {
                if (response.status === 201 || response.status === 200) {
                    onInserted()
                    resetWorklogData()
                }
            }).catch(error => {
                openSnackBar(i18n.t("worklogModal.errorWorklogEdit"), "error")
            })
    }

    const updateClient = () => {
        axios.patch(getClients + "?id=" + id, {
            name: name,
            hidden: hiddenType.id
        },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
            }).then(response => {
                if (response.status === 201 || response.status === 200) {
                    onInserted()
                    resetWorklogData()
                }
            }).catch(error => {
                openSnackBar(i18n.t("worklogModal.errorWorklogEdit"), "error")
            })
    }

    const updateProject = () => {
        axios.patch(getProjects + "?id=" + id, {
            name: name,
            client_id: clientValue.id,
            hidden: hiddenType.id
        },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
            }).then(response => {
                if (response.status === 201 || response.status === 200) {
                    onInserted()
                    resetWorklogData()
                }
            }).catch(error => {
                openSnackBar(i18n.t("worklogModal.errorWorklogEdit"), "error")
            })
    }

    const onDismissView = () => {
        setOpen(false)
    }

    useImperativeHandle(ref, () => ({
        setOpen,
        setId,
        setFilteredData,
        setName,
        setFirstName,
        setLastName,
        setEmployeeType,
        setHiddenType,
        setHours,
        setStartDate,
        setEndDate,
        setClientValue
    }))

    const getTitle = () => {
        if (id) {
            if (filteredData === 1) {
                return 'Edit user'
            } else if (filteredData === 2) {
                return 'Edit client'
            } else if (filteredData === 3) {
                return 'Edit project'
            }
        } else {
            if (filteredData === 1) {
                return 'Add user'
            } else if (filteredData === 2) {
                return 'Add client'
            } else if (filteredData === 3) {
                return 'Add project'
            }
        }
    }

    const getTextField = (label, value, error, onChange) => {
        return (
            <TextField
                label={label}
                error={error === "" ? false : true}
                variant="outlined"
                value={value}
                onChange={onChange}
                helperText={error}
                className={classes.generalField}
                InputLabelProps={{
                    classes: {
                        root: classes.textFieldLabel,
                        focused: classes.focusedLabel
                    }
                }}
            />
        )
    }

    const getDateField = (label, onChange, value) => {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    disableToolbar
                    format="yyyy-MM-dd"
                    label={label}
                    value={value}
                    onChange={onChange}
                    TextFieldComponent={renderInput}
                />
            </MuiPickersUtilsProvider>
        )
    }

    const renderInput = (props) => {
        return (
            <TextField
                key='dateTextfield'
                label={props.label}
                variant="outlined"
                value={props.value}
                onClick={props.onClick}
                className={classes.generalField}
                InputLabelProps={{
                    classes: {
                        root: classes.textFieldLabel,
                        focused: classes.focusedLabel
                    },
                }}
            />
        )
    }

    useEffect(() => {
        getClient()
        // eslint-disable-next-line
    }, [])

    return (
        <Dialog
            open={open}
            scroll="body"
            maxWidth="lg"
            onClose={() => id ? resetWorklogData() : onDismissView()}
            PaperProps={{
                style: {
                    backgroundColor: Colors.thirdColor
                },
            }} >
            <DialogContent>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Typography className={classes.title}>
                        {getTitle()}
                    </Typography>

                    {filteredData === 1 &&
                        <>
                            {getTextField('First name', firstName, firstNameError, handleFirstChange)}
                            {getTextField('Last name', lastName, lastNameError, handleLastNameChange)}
                            {!id && getTextField('Password', password, passwordError, handlePasswordChange)}

                            <Autocomplete
                                className={classes.select}
                                disableClearable
                                options={employeeTypes}
                                value={employeeType}
                                onChange={handleEmployeeTypeChange}
                                getOptionLabel={(option) => (option.name)}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='User type'
                                        variant="outlined"
                                    />
                                )}
                            />

                            {getTextField('Hours', hours, hoursError, handleHoursChange)}
                            {getDateField('Start date', handleStartDateChange, startDate)}
                            {id && getDateField('End date', handleEndDateChange, endDate)}
                        </>
                    }

                    {filteredData === 2 &&
                        <>
                            {getTextField('Client name', name, nameError, handleNameChange)}

                            <Autocomplete
                                className={classes.select}
                                disableClearable
                                options={hiddenTypes}
                                value={hiddenType}
                                onChange={handleHiddenTypeChange}
                                getOptionLabel={(option) => (option.name)}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Is hidden'
                                        variant="outlined"
                                    />
                                )}
                            />
                        </>
                    }

                    {filteredData === 3 &&
                        <>
                            {getTextField('Project name', name, nameError, handleNameChange)}

                            <Autocomplete
                                className={classes.select}
                                disableClearable
                                options={clients}
                                value={clientValue}
                                onChange={handleClientChange}
                                getOptionLabel={(option) => (option.name)}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Client name'
                                        variant="outlined"
                                    />
                                )}
                            />

                            <Autocomplete
                                className={classes.select}
                                disableClearable
                                options={hiddenTypes}
                                value={hiddenType}
                                onChange={handleHiddenTypeChange}
                                getOptionLabel={(option) => (option.name)}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Is hidden'
                                        variant="outlined"
                                        error={clientValueError === "" ? false : true}
                                        helperText={clientValueError}
                                    />
                                )}
                            />
                        </>
                    }
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    className={classes.actionButtons}
                    onClick={() => { id ? updateEntry() : addEntry() }}>
                    {id ? i18n.t("worklogModal.editButton") : i18n.t("worklogModal.addButton")}
                </Button>
                <Button
                    className={classes.actionButtons}
                    onClick={() => resetWorklogData()}>
                    {i18n.t("worklogModal.cancelButton")}
                </Button>
            </DialogActions>

            <SnackBar ref={snackbarRef} />
        </Dialog>
    )
}

export default forwardRef(DashboardModal)