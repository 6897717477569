const Colors = {
    firstColor: "#155787",
    secondColor: "#5B89AB",
    thirdColor: "#E7EEF3",
    whiteColor: "#FFFFFF",
    blackColor: "#000000",
    redColor: "#FF0000",
    greyColor: "#808080",
    lightGreyColor: "#E7EEF3",
    yellow: "#FFFFED",
    lightGrey: '#D3D3D3'
}

export default Colors