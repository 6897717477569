import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

//internal
import Colors from '../globals/Colors';
import SnackBar from './Snackbar';

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: 350
    },
    title: {
        fontSize: 22,
        fontWeight: 700
    },
    dateButton: {
        marginRight: -15,
        '&:hover': {
            backgroundColor: "transparent"
        }
    },
    select: {
        width: "100%",
        marginTop: 20,
        '& .MuiInputLabel-root.Mui-focused': {
            color: Colors.firstColor,
        },
    },
    selectBorder: {
        '&:hover': {
            borderColor: Colors.firstColor,
        },
        '&:before': {
            borderColor: Colors.firstColor,
        },
        '&:after': {
            borderColor: Colors.firstColor,
        }
    },
    actionButtons: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        fontSize: 14,
        marginTop: 20,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
    textFieldLabel: {
        "&$focusedLabel": {
            color: Colors.firstColor
        },
    },
    focusedLabel: {}
})

const icon = <CheckBoxOutlineBlankIcon fontSize="small" style={{ color: Colors.firstColor }} />
const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: Colors.firstColor }} />
const dropdownData = [{ id: 1, name: 'Users' }, { id: 2, name: 'Clients' }, { id: 3, name: 'Projects' }, { id: 4, name: 'Asign projects' },]

function DashboardFilterModal({ setFilters, clearFilters }, ref) {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)

    const [data, setData] = useState(JSON.parse(sessionStorage.getItem("managementFilter"))?.id || 4)
    const [inputData, setInputData] = useState(JSON.parse(sessionStorage.getItem("managementFilter")) || dropdownData[3])

    const snackbarRef = useRef()

    const applySessionStorage = () => {
        sessionStorage.setItem("managementFilter", JSON.stringify(inputData))
    }

    const handleDataChange = (event, newValue) => {
        setData(newValue.id)
        setInputData(newValue)
    }

    const resetValues = () => {
        setInputData(dropdownData[3])
        setData(dropdownData[3].id)
        sessionStorage.removeItem("managementFilter")
    }

    const onCancel = () => {
        setOpen(false)
        setTimeout(() => {
            setData(JSON.parse(sessionStorage.getItem("managementFilter"))?.id)
            setInputData(JSON.parse(sessionStorage.getItem('managementFilter')) || dropdownData[3])
        }, 100)
    }

    useImperativeHandle(ref, () => ({
        setOpen
    }))

    return (
        <Dialog open={open}
            scroll="body"
            maxWidth="md"
            onClose={() => onCancel()}
            PaperProps={{
                style: {
                    backgroundColor: Colors.thirdColor
                },
            }} >
            <DialogContent className={classes.root}>
                <Typography className={classes.title}>Filter database</Typography>

                <Autocomplete
                    className={classes.select}
                    disableClearable
                    options={dropdownData}
                    value={inputData}
                    onChange={handleDataChange}
                    getOptionLabel={(option) => (option.name)}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Select'
                            variant="outlined"
                        />
                    )}
                />

            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.actionButtons}
                    onClick={() => { setOpen(false); applySessionStorage(); setFilters(data) }}>
                    {i18n.t("timeoffFilterModal.apply")}
                </Button>
                <Button
                    className={classes.actionButtons}
                    onClick={() => { setOpen(false); resetValues(); clearFilters() }}
                    style={{ marginLeft: 20 }}>
                    {i18n.t("timeoffFilterModal.clear")}
                </Button>
                <Button
                    className={classes.actionButtons}
                    style={{ marginLeft: 20 }}
                    onClick={() => { setOpen(false); onCancel() }}>
                    {i18n.t("timeoffFilterModal.cancel")}
                </Button>
            </DialogActions>

            <SnackBar ref={snackbarRef} />
        </Dialog>
    )
}

export default forwardRef(DashboardFilterModal)