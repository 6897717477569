import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, FormControl, InputLabel, Checkbox, IconButton, InputAdornment, OutlinedInput, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

//internal
import Colors from '../globals/Colors';
import SnackBar from './Snackbar';
import DateRangeModal from './DateRangeModal';
import { getUsers, getTimeOffProjects } from '../globals/Routes';

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: 350
    },
    title: {
        fontSize: 22,
        fontWeight: 700
    },
    dateButton: {
        marginRight: -15,
        '&:hover': {
            backgroundColor: "transparent"
        }
    },
    select: {
        width: "100%",
        marginTop: 20,
        '& .MuiInputLabel-root.Mui-focused': {
            color: Colors.firstColor,
        },
    },
    selectBorder: {
        '&:hover': {
            borderColor: Colors.firstColor,
        },
        '&:before': {
            borderColor: Colors.firstColor,
        },
        '&:after': {
            borderColor: Colors.firstColor,
        }
    },
    actionButtons: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        fontSize: 14,
        marginTop: 20,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
    textFieldLabel: {
        "&$focusedLabel": {
            color: Colors.firstColor
        },
    },
    focusedLabel: {}
})

const firstDay = new Date(new Date().getFullYear(), 0, 1)
const lastDay = new Date(new Date().getFullYear(), 11, 31)
const icon = <CheckBoxOutlineBlankIcon fontSize="small" style={{ color: Colors.firstColor }} />
const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: Colors.firstColor }} />

function FilterModal({ setFilters, clearFilters }, ref) {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)

    const [currentUser, setCurrentUser] = useState(0)

    const [users, setUsers] = useState([])
    const [userValue, setUserValue] = useState(JSON.parse(sessionStorage.getItem("userTimeoff")) || [JSON.parse(localStorage.getItem('user')).id])
    const [consistenceFilteredUser, setConsistenceFilteredUser] = useState(JSON.parse(sessionStorage.getItem("consistenceTimeoffFilteredUsers")) || [JSON.parse(localStorage.getItem('user'))])

    const [projects, setProjects] = useState([])
    const [projectValue, setProjectValue] = useState(JSON.parse(sessionStorage.getItem("projectTimeoff")) || [])
    const [consistenceProject, setConsistenceProject] = useState(JSON.parse(sessionStorage.getItem("consistenceTimeoffFilteredProjects")) || [])

    const [date, setDate] = useState(JSON.parse(sessionStorage.getItem("dateTimeoff")) || null)
    const [startDate, setStartDate] = useState(moment(firstDay).format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment(lastDay).format("YYYY-MM-DD"))

    const dateRangeModalRef = useRef()
    const snackbarRef = useRef()

    const applySessionStorage = () => {
        sessionStorage.setItem("userTimeoff", JSON.stringify(userValue))
        sessionStorage.setItem('consistenceTimeoffFilteredUsers', JSON.stringify(consistenceFilteredUser))
        sessionStorage.setItem("projectTimeoff", JSON.stringify(projectValue))
        sessionStorage.setItem('consistenceTimeoffFilteredProjects', JSON.stringify(consistenceProject))
        sessionStorage.setItem("dateTimeoff", JSON.stringify(date))
    }

    const handleUserChange = (event, newValue) => {
        if (newValue != null) {
            setUserValue(newValue.map(value => value.id))
            setConsistenceFilteredUser(newValue)
        }
    }

    const handleProjectChange = (event, newValue) => {
        if (newValue != null) {
            setProjectValue(newValue.map(value => value.id))
            setConsistenceProject(newValue)
        }
    }

    const handleDateChange = date => {
        setDate(date)
    }

    const setDates = data => {
        setStartDate(transformDate(data.startDate))
        setEndDate(transformDate(data.endDate))
    }

    const resetValues = () => {
        setUserValue([JSON.parse(localStorage.getItem('user')).id])
        sessionStorage.removeItem("userTimeoff")
        sessionStorage.removeItem('consistenceTimeoffFilteredUsers')
        setProjectValue([])
        setConsistenceProject([])
        setDate(null)
        sessionStorage.removeItem("projectTimeoff")
        sessionStorage.removeItem('consistenceTimeoffFilteredProjects')
        sessionStorage.removeItem("dateTimeoff")
        sessionStorage.removeItem("filtersYear")
        setStartDate(moment(firstDay).format("YYYY-MM-DD"))
        setEndDate(moment(lastDay).format("YYYY-MM-DD"))
        setConsistenceFilteredUser([JSON.parse(localStorage.getItem('user'))])
    }

    const onCancel = () => {
        setOpen(false)
        setTimeout(() => {
            setUserValue(JSON.parse(sessionStorage.getItem('userTimeoff')) || [JSON.parse(localStorage.getItem('user')).id])
            setConsistenceFilteredUser(JSON.parse(sessionStorage.getItem('consistenceTimeoffFilteredUsers')) || [JSON.parse(localStorage.getItem('user'))])
            setDate(JSON.parse(sessionStorage.getItem("dateTimeoff")) || null)
            setProjectValue(JSON.parse(sessionStorage.getItem("projectTimeoff")) || [])
            setConsistenceProject(JSON.parse(sessionStorage.getItem("consistenceTimeoffFilteredProjects")) || [])
            setStartDate(JSON.parse(sessionStorage.getItem("dateTimeoff")) ? moment(JSON.parse(sessionStorage.getItem("dateTimeoff"))[0].startDate).format('MMM DD, YYYY') : moment(firstDay).format("YYYY-MM-DD"))
            setEndDate(JSON.parse(sessionStorage.getItem("dateTimeoff")) ? moment(JSON.parse(sessionStorage.getItem("dateTimeoff"))[0].endDate).format('MMM DD, YYYY') : moment(lastDay).format("YYYY-MM-DD"))
        }, 100)
    }

    const openSnackBar = (message, severity = "success") => {
        snackbarRef.current.setOpen(true)
        snackbarRef.current.setMessage(message)
        snackbarRef.current.setSeverity(severity)
    }

    const getDatabaseData = () => {
        axios.get(getUsers, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackBar(i18n.t("timeoffFilterModal.errorUsers"), "error")
            } else {
                setUsers(response.data.filter(user => !user.endDate))
            }
        })

        axios.get(getTimeOffProjects, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackBar(i18n.t("timeoffFilterModal.errorProjects"), "error")
            } else {
                setProjects(response.data)
            }
        })
    }

    const transformDate = value => {
        let date = new Date(value),
            month = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), month, day].join("-");
    }

    const renderUserValues = selected => {
        let shownValues = users.filter(user => selected.includes(user.id)).map(user => { return user.name })
        return [...shownValues].join(", ")
    }

    const renderProjectValues = selected => {
        let shownValues = projects.filter(project => selected.includes(project.id)).map(project => { return project.projectName })
        return [...shownValues].join(", ")
    }

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem("user")))
        getDatabaseData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (date) {
            date.map(data =>
                setDates(data)
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])

    useImperativeHandle(ref, () => ({
        setOpen,
        setUserValue,
        setProjectValue,
        setStartDate,
        setEndDate,
        setConsistenceFilteredUser,
        setConsistenceProject
    }))

    return (
        <Dialog open={open}
            scroll="body"
            maxWidth="md"
            onClose={() => onCancel()}
            PaperProps={{
                style: {
                    backgroundColor: Colors.thirdColor
                },
            }} >
            <DialogContent className={classes.root}>
                <Typography className={classes.title}>{i18n.t("timeoffFilterModal.filterRecords")}</Typography>

                <FormControl
                    className={classes.select}
                    variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{i18n.t("timeoffFilterModal.dateRange")}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        value={moment(startDate).format('MMM DD, YYYY') + " - " + moment(endDate).format('MMM DD, YYYY')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    disableRipple={true}
                                    className={classes.dateButton}
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        dateRangeModalRef.current.setOpen(true)
                                        dateRangeModalRef.current.setDate([
                                            {
                                                startDate: new Date(startDate),
                                                endDate: new Date(endDate),
                                                key: 'selection'
                                            }
                                        ])
                                    }}
                                >
                                    <DateRangeIcon
                                        style={{ width: 25, height: 25 }} />
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={82}
                    />
                </FormControl>

                <DateRangeModal ref={dateRangeModalRef} onChangeDate={handleDateChange} />

                {currentUser && currentUser.role === 1 &&
                    <Autocomplete
                        multiple
                        className={classes.select}
                        disableCloseOnSelect
                        options={users}
                        value={consistenceFilteredUser}
                        onChange={handleUserChange}
                        getOptionLabel={(option) => (option.name)}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </React.Fragment>
                        )}
                        renderTags={option => renderUserValues(userValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={i18n.t("timeoffFilterModal.filterByUser")}
                                variant="outlined"
                            />
                        )}
                    />
                }

                <Autocomplete
                    multiple
                    className={classes.select}
                    disableCloseOnSelect
                    options={projects}
                    value={consistenceProject}
                    onChange={handleProjectChange}
                    getOptionLabel={(option) => (option.projectName)}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.projectName}
                        </React.Fragment>
                    )}
                    renderTags={option => renderProjectValues(projectValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={i18n.t("timeoffFilterModal.filterByProject")}
                            variant="outlined"
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.actionButtons}
                    onClick={() => { setOpen(false); setFilters({ startDate, endDate, userValue, projectValue }); applySessionStorage() }}>
                    {i18n.t("timeoffFilterModal.apply")}
                </Button>
                <Button
                    className={classes.actionButtons}
                    onClick={() => { setOpen(false); resetValues(); clearFilters() }}
                    style={{ marginLeft: 20 }}>
                    {i18n.t("timeoffFilterModal.clear")}
                </Button>
                <Button
                    className={classes.actionButtons}
                    style={{ marginLeft: 20 }}
                    onClick={() => { setOpen(false); onCancel() }}>
                    {i18n.t("timeoffFilterModal.cancel")}
                </Button>
            </DialogActions>
            <SnackBar ref={snackbarRef} />
        </Dialog>
    )
}

export default forwardRef(FilterModal)