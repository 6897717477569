import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

function SnackBar({ props }, ref) {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("info")

    const handleClose = (event, reason) => {
        setOpen(false)
    }

    useImperativeHandle(ref, () => ({
        setOpen,
        setMessage,
        setSeverity
    }))

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            onClose={handleClose}
            autoHideDuration={2000}
            key={"bottomleft"}
        >
            <Alert onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    )
}


export default forwardRef(SnackBar)