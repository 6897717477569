import React from 'react';
import font from '../fonts/Roboto-Medium.ttf';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import CristianSerea from '../assets/icons/CristianSerea.png'
import MihailStoica from '../assets/icons/MihailStoica.png'
import VladPopa from '../assets/icons/VladPopa.png'

Font.register({ family: 'Roboto', src: font });

const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        textAlign: "left"
    },
    titleDiv: {
        marginTop: 50,
        textAlign: "center"
    },
    titleText: {
        fontSize: 24,
        fontFamily: 'Roboto'
    },
    contentDiv: {
        marginVertical: 50,
        marginHorizontal: 70
    },
    contentText: {
        fontSize: 16,
        fontFamily: 'Roboto'
    },
    footerDiv: {
        flexDirection: "row",
        marginRight: 70,
        marginLeft: 70
    },
    footerSeparator: {
        flex: 1
    },
    footerDate: {
        flexDirection: "column"
    },
    footerText: {
        fontSize: 20,
        fontFamily: 'Roboto'
    },
    signatureView: {
        marginTop: 20,
        width: 100,
        height: 50
    }
})

export default function TimeOffRequest({ user, days, type, startDate, endDate, reason }) {
    const { i18n } = useTranslation()

    const getUserSignature = () => {
        switch (user) {
            case 'Vlad Popa':
                return VladPopa

            case 'Cristian Serea':
                return CristianSerea

            case 'Mihail Stoica':
                return MihailStoica

            default:
                break;
        }
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.titleDiv}>
                    <Text style={styles.titleText}>Cerere concediu</Text>
                </View>
                <View style={styles.contentDiv}>
                    <Text style={styles.contentText}>Subsemnatul {user}, angajat al Sprint Mobile S.R.L.
                        vă rog să îmi aprobați {days !== 0 ? `${days} zile` : "o zi"} de {type}{type === i18n.t("timeoff.CFP") && ', din motive personale,'}{type === i18n.t("timeoff.CZS") && `, având motivul ${reason.toUpperCase()},`}{days === 0 ? ` pe data de ${moment(new Date(startDate)).format('MMM DD, YYYY')}` : ` în perioada ${moment(new Date(startDate)).format('MMM DD, YYYY')} - ${moment(new Date(endDate)).format('MMM DD, YYYY')}`}.
                    </Text>
                </View>
                <View style={styles.footerDiv}>
                    <View style={styles.footerDate}>
                        <Text>Data</Text>
                    </View>

                    <View style={styles.footerSeparator} />

                    <Text style={styles.footerText}>
                        {moment(new Date()).format('MMM DD, YYYY')}
                    </Text>
                </View>

                <View style={styles.footerDiv}>
                    <View style={styles.footerDate}>
                        <Text>Angajator</Text>

                        <Image
                            source={MihailStoica}
                            style={styles.signatureView}
                        />
                    </View>

                    <View style={styles.footerSeparator} />

                    <View style={[styles.footerDate, { alignItems: 'flex-end' }]}>
                        <Text>Angajat</Text>

                        <Image
                            source={getUserSignature()}
                            style={styles.signatureView}
                        />
                    </View>
                </View>
            </Page>
        </Document>
    )
}