import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Dialog, Button, DialogContent, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

//internal
import Colors from '../globals/Colors';

const useStyles = makeStyles({
    dialogTypography: {
        fontSize: 20,
        textAlign: 'center'
    },
    dialogRecord: {
        fontSize: 18,
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    dialogButtons: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        fontSize: 14,
        marginTop: 20,
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
})

function DeleteModal({ deleteWorklog, deleteUser, deleteClient, deleteProject }, ref) {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const [open, setOpen] = useState(false)

    const [selectedRow, setSelectedRow] = useState()

    const getText = () => {
        if (selectedRow?.worklog) {
            return (
                `${new Date(selectedRow.worklog.date).toISOString().slice(0, 10)}, ${selectedRow.worklog.user}, ${selectedRow.worklog.project} - ${selectedRow.worklog.client}, ${selectedRow.worklog.duration}h, ${selectedRow.worklog.task}${selectedRow.worklog.description && ','} ${selectedRow.worklog.description}`
            )
        } else if (selectedRow?.user) {
            return (
                `${selectedRow.user.name} - ${selectedRow.user.email}`
            )
        } else if (selectedRow?.client) {
            return (
                `${selectedRow.client.name}`
            )
        } else if (selectedRow?.project) {
            return (
                `${selectedRow.project.name} - ${selectedRow.project.clientName}`
            )
        }
    }

    const onDelete = () => {
        if (selectedRow?.worklog) {
            deleteWorklog(selectedRow.worklog.id)
        } else if (selectedRow?.user) {
            deleteUser(selectedRow.user.id)
        } else if (selectedRow?.client) {
            deleteClient(selectedRow.client.id)
        } else if (selectedRow?.project) {
            deleteProject(selectedRow.project.id)
        }
    }

    useImperativeHandle(ref, () => ({
        setOpen,
        setSelectedRow
    }))

    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false) }}
            PaperProps={{
                style: {
                    backgroundColor: Colors.thirdColor
                }
            }}>
            <DialogContent style={{ flexDirection: "column" }}>
                <Typography className={classes.dialogTypography}>
                    {i18n.t("worklogs.deleteRecord")}
                </Typography>

                <Typography className={classes.dialogRecord}>
                    {getText()}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button className={classes.dialogButtons} onClick={() => { setOpen(false) }}>
                    {i18n.t("worklogs.cancel")}
                </Button>
                <Button className={classes.dialogButtons} onClick={onDelete}>
                    {i18n.t("worklogs.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default forwardRef(DeleteModal)